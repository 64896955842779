import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ActivityListResolver } from 'src/app/shared/resolvers/activity-list.resolver';
import { ActivityTransitionListResolver } from 'src/app/shared/resolvers/activity-transition-list.resolver';
import { ActivityTransitionResolver } from 'src/app/shared/resolvers/activity-transition.resolver';
import { ActivityResolver } from 'src/app/shared/resolvers/activity.resolver';
import { CarrierListResolver } from 'src/app/shared/resolvers/carrier-list.resolver';
import { CarrierResolver } from 'src/app/shared/resolvers/carrier.resolver';
import { ChargeTypeListResolver } from 'src/app/shared/resolvers/charge-type-list-resolver.resolver';
import { ChargeTypeResolver } from 'src/app/shared/resolvers/charge-type.resolver';
import { CitiesListResolver } from 'src/app/shared/resolvers/cities-list.resolver';
import { CityResolver } from 'src/app/shared/resolvers/city.resolver';
import { CurrencyResolver } from 'src/app/shared/resolvers/currency-list.resolver';
import { DistrictResolver } from 'src/app/shared/resolvers/district.resolver';
import { DistrictsListResolver } from 'src/app/shared/resolvers/districts-list.resolver';
import { DocumentTypeListResolver } from 'src/app/shared/resolvers/document-type-list.resolver';
import { DocumentTypeResolver } from 'src/app/shared/resolvers/document-type.resolver';
import { HSCodeResolver } from 'src/app/shared/resolvers/hs-code.resolver';
import { IndustryListResolver } from 'src/app/shared/resolvers/industry-list.resolver';
import { IndustryResolver } from 'src/app/shared/resolvers/industry.resolver';
import { PortsResolver } from 'src/app/shared/resolvers/ports.resolver';
import { ServicesResolver } from 'src/app/shared/resolvers/services.resolver';
import { ShipmentActivityProgressesResolver } from 'src/app/shared/resolvers/shipment-activity-progresses.resolver';
import { ShipmentStatusesResolver } from 'src/app/shared/resolvers/shipment-statuses.resolver';
import { SettingsComponent } from '../shared/components/settings/settings.component';
import { ActivityTransitionListComponent } from './pages/activity-transition/activity-transition-list/activity-transition-list.component';
import { AddOrEditActivityTransitionComponent } from './pages/activity-transition/add-or-edit-activity-transition/add-or-edit-activity-transition.component';
import { ActivityListComponent } from './pages/activity/activity-list/activity-list.component';
import { AddOrEditActivityComponent } from './pages/activity/add-or-edit-activity/add-or-edit-activity.component';
import { AddOrEditCarrierComponent } from './pages/carrier/add-or-edit-carrier/add-or-edit-carrier.component';
import { CarrierListComponent } from './pages/carrier/carrier-list/carrier-list.component';
import { AddOrEditChargeTypeComponent } from './pages/charge-type/add-or-edit-charge-type/add-or-edit-charge-type.component';
import { ChargeTypeListComponent } from './pages/charge-type/charge-type-list/charge-type-list.component';
import { AddOrEditCitiesComponent } from './pages/cities/add-or-edit-cities/add-or-edit-cities.component';
import { CitiesListComponent } from './pages/cities/cities-list/cities-list.component';
import { CurrencyListComponent } from './pages/currency/currency-list/currency-list.component';
import { AddOrEditDistrictsComponent } from './pages/ditricts/add-or-edit-districts/add-or-edit-districts.component';
import { DistrictsListComponent } from './pages/ditricts/districts-list/districts-list.component';
import { AddOrEditDocumentTypeComponent } from './pages/document-type/add-or-edit-document-type/add-or-edit-document-type.component';
import { DocumentTypesListComponent } from './pages/document-type/document-type-list/document-types-list.component';
import { HsCodeListComponent } from './pages/hs-code/hs-code-list/hs-code-list.component';
import { AddOrEditIndustryComponent } from './pages/industry/add-or-edit-industry/add-or-edit-industry.component';
import { IndustryListComponent } from './pages/industry/industry-list/industry-list.component';
import { PortListComponent } from './pages/port/port-list/port-list.component';
import { AddOrEditRegistrationActivityTransitionComponent } from './pages/registration-activity-transition/add-or-edit-registration-activity-transition/add-or-edit-registration-activity-transition.component';
import { RegistrationActivityTransitionListComponent } from './pages/registration-activity-transition/registration-activity-transition-list/registration-activity-transition-list.component';
import { AddOrEditRegistrationActivityComponent } from './pages/registration-activity/add-or-edit-registration-activity/add-or-edit-registration-activity.component';
import { RegistrationActivityListComponent } from './pages/registration-activity/registration-activity-list/registration-activity-list.component';
import { AddOrEditShipmentLeadTimeComponent } from './pages/shipment-lead-time/add-or-edit-shipment-lead-time/add-or-edit-shipment-lead-time.component';
import { ShipmentLeadTimeListComponent } from './pages/shipment-lead-time/shipment-lead-time-list/shipment-lead-time-list.component';
import { AddOrEditTruckTypeComponent } from './pages/truck-type/add-or-edit-truck-type/add-or-edit-truck-type.component';
import { TruckTypeListComponent } from './pages/truck-type/truck-type-list/truck-type-list.component';
import { RegistrationActivityListResolver } from './resolvers/registration-activity-list.resolver';
import { RegistrationActivityTransitionListResolver } from './resolvers/registration-activity-transition-list.resolver';
import { RegistrationActivityTransitionResolver } from './resolvers/registration-activity-transition.resolver';
import { RegistrationActivityResolver } from './resolvers/registration-activity.resolver';
import { ShipmentLeadTimeListResolver } from './resolvers/shipment-lead-time-list.resolver';
import { ShipmentLeadTimeResolver } from './resolvers/shipment-lead-time.resolver';
import { TruckTypeListResolver } from './resolvers/truck-type-list.resolver';
import { TruckTypeResolver } from './resolvers/truck-type.resolver';
import { ProductRegistrationKpisListComponent } from './pages/product-registration-kpis/product-registration-kpis-list/product-registration-kpis-list.component';
import { AddOrEditProductRegistrationKpisComponent } from './pages/product-registration-kpis/add-or-edit-product-registration-kpis/add-or-edit-product-registration-kpis.component';
import { ProductRegistrationKpiListResolver } from './resolvers/product-registration-kpi-list.resolver';
import { ProductRegistrationKpiResolver } from './resolvers/product-registration-kpi.resolver';
import { ProductCategoryListComponent } from './pages/product-category/product-category-list/product-category-list.component';
import { ProductCategoryListResolver } from './resolvers/product-category-list.resolver';
import { ProductCategoryResolver } from './resolvers/product-category.resolver';
import { AddOrEditProductCategoryComponent } from './pages/product-category/add-or-edit-product-category/add-or-edit-product-category.component';
import { AddEditRoleComponent } from './pages/add-edit-role/add-edit-role.component';
import { RoleResolver } from './resolvers/role.resolver';
import { CategoryPermissionsResolver } from './resolvers/category-permissions.resolver';
import { ApprovalActivityListComponent } from './pages/approval-activity-list/approval-activity-list.component';
import { AddOrEditApprovalActivityComponent } from './pages/add-or-edit-approval-activity/add-or-edit-approval-activity.component';
import { ApprovalActivitiesListResolver } from './resolvers/approval-activities-list.resolver';
import { ApprovalActivityResolver } from './resolvers/approval-activity.resolver';
import { ApprovalActivityLookupsResolver } from './resolvers/approval-activity-lookups.resolver';
import { ApprovalActivityTransitionListResolver } from './resolvers/approval-activity-transition-list.resolver';
import { AddOrEditApprovalActivityTransitionComponent } from './pages/approval-activity-transition/add-or-edit-approval-activity-transition/add-or-edit-approval-activity-transition.component';
import { ApprovalActivityTransitionResolver } from './resolvers/approval-activity-transition.resolver';
import { ApprovalActivityTransitionListComponent } from './pages/approval-activity-transition/approval-activity-transition-list/approval-activity-transition-list.component';
import { ApprovalKpisListComponent } from './pages/approval-kpis/approval-kpis-list/approval-kpis-list.component';
import { ApprovalKpisListResolver } from './resolvers/approval-kpis-list.resolver';
import { AddOrEditApprovalKpisComponent } from './pages/approval-kpis/add-or-edit-approval-kpis/add-or-edit-approval-kpis.component';
import { ApprovalKpiResolver } from './resolvers/approval-kpi.resolver';
import { ChargeTypeLookupsResolver } from '../shared/resolvers/charge-type-lookups.resolver';
import { PermissionGroupsResolver } from './resolvers/permission-groups.resolver';
import { CountriesListComponent } from './pages/countries/countries-list/countries-list.component';
import { CountriesResolver } from './resolvers/countries.resolver';
import { AddOrEditCountryComponent } from './pages/countries/add-or-edit-country/add-or-edit-country.component';

const routes: Routes = [
  {
    path: '',
    component: SettingsComponent,
    pathMatch: 'full'
  },
  {
    path: 'roles',
    children: [
      {
        path: 'add',
        component: AddEditRoleComponent,
        pathMatch: 'full',
        resolve: {
          categoryPermissions: CategoryPermissionsResolver,
          permissionGroups: PermissionGroupsResolver
        }
      },
      {
        path: ':id/edit',
        component: AddEditRoleComponent,
        resolve: {
          roleAndPermissions: RoleResolver,
          categoryPermissions: CategoryPermissionsResolver,
          permissionGroups: PermissionGroupsResolver
        },
        pathMatch: 'full'
      }
    ]
  },
  {
    path: 'document-type',
    children: [
      {
        path: '',
        component: DocumentTypesListComponent,
        resolve: { documentTypes: DocumentTypeListResolver }
      },
      {
        path: 'add',
        component: AddOrEditDocumentTypeComponent,
        resolve: { services: ServicesResolver }
      },
      {
        path: ':id/edit',
        component: AddOrEditDocumentTypeComponent,
        resolve: {
          documentType: DocumentTypeResolver,
          services: ServicesResolver
        }
      }
    ]
  },
  {
    path: 'charge-type',
    children: [
      {
        path: '',
        component: ChargeTypeListComponent,
        resolve: { chargeTypes: ChargeTypeListResolver }
      },
      {
        path: 'add',
        component: AddOrEditChargeTypeComponent,
        resolve: {
          lookups: ChargeTypeLookupsResolver
        }
      },
      {
        path: ':id/edit',
        component: AddOrEditChargeTypeComponent,
        resolve: {
          chargeType: ChargeTypeResolver,
          lookups: ChargeTypeLookupsResolver
        }
      }
    ]
  },
  {
    path: 'currency',
    component: CurrencyListComponent,
    resolve: { Currencies: CurrencyResolver }
  },
  {
    path: 'ports',
    component: PortListComponent,
    resolve: { ports: PortsResolver }
  },
  {
    path: 'hs-code',
    component: HsCodeListComponent,
    resolve: { HSCodes: HSCodeResolver }
  },
  {
    path: 'activity-transition',
    children: [
      {
        path: '',
        component: ActivityTransitionListComponent,
        resolve: { activityTransitions: ActivityTransitionListResolver }
      },
      {
        path: 'add',
        component: AddOrEditActivityTransitionComponent,
        resolve: { activityTransitions: ActivityTransitionListResolver }
      },
      {
        path: ':id/edit',
        component: AddOrEditActivityTransitionComponent,
        resolve: {
          currentActivity: ActivityTransitionResolver,
          activityTransitions: ActivityTransitionListResolver
        }
      }
    ]
  },
  {
    path: 'activity',
    children: [
      {
        path: '',
        component: ActivityListComponent,
        resolve: { activities: ActivityListResolver }
      },
      {
        path: 'add',
        component: AddOrEditActivityComponent,
        resolve: {
          activities: ActivityListResolver,
          statuses: ShipmentStatusesResolver,
          progresses: ShipmentActivityProgressesResolver
        }
      },
      {
        path: ':id/edit',
        component: AddOrEditActivityComponent,
        resolve: {
          activity: ActivityResolver,
          statuses: ShipmentStatusesResolver,
          progresses: ShipmentActivityProgressesResolver
        }
      }
    ]
  },
  {
    path: 'districts',
    children: [
      {
        path: '',
        component: DistrictsListComponent,
        resolve: { districts: DistrictsListResolver }
      },
      {
        path: 'add',
        component: AddOrEditDistrictsComponent
      },
      {
        path: ':id/edit',
        component: AddOrEditDistrictsComponent,
        resolve: {
          district: DistrictResolver
        }
      }
    ]
  },
  {
    path: 'cities',
    children: [
      {
        path: '',
        component: CitiesListComponent,
        resolve: { paginatedCities: CitiesListResolver }
      },
      {
        path: 'add',
        component: AddOrEditCitiesComponent
      },
      {
        path: ':id/edit',
        component: AddOrEditCitiesComponent,
        resolve: { city: CityResolver }
      }
    ]
  },
  {
    path: 'industries',
    children: [
      {
        path: '',
        component: IndustryListComponent,
        resolve: { industries: IndustryListResolver }
      },
      {
        path: 'add',
        component: AddOrEditIndustryComponent
      },
      {
        path: ':id/edit',
        component: AddOrEditIndustryComponent,
        resolve: {
          industry: IndustryResolver
        }
      }
    ]
  },
  {
    path: 'carriers',
    children: [
      {
        path: '',
        component: CarrierListComponent,
        resolve: { carriers: CarrierListResolver }
      },
      {
        path: 'add',
        component: AddOrEditCarrierComponent
      },
      {
        path: ':id/edit',
        component: AddOrEditCarrierComponent,
        resolve: {
          carrier: CarrierResolver
        }
      }
    ]
  },
  {
    path: 'countries',
    children: [
      {
        path: '',
        component: CountriesListComponent,
        resolve: {
          countries: CountriesResolver
        }
      },
      {
        path: 'add',
        component: AddOrEditCountryComponent
      }
    ]
  },
  {
    path: 'shipment-kpis',
    children: [
      {
        path: '',
        component: ShipmentLeadTimeListComponent,
        resolve: { shipmentLeadTimes: ShipmentLeadTimeListResolver }
      },
      {
        path: 'add',
        component: AddOrEditShipmentLeadTimeComponent
      },
      {
        path: ':id/edit',
        component: AddOrEditShipmentLeadTimeComponent,
        resolve: {
          shipmentLeadTime: ShipmentLeadTimeResolver
        }
      }
    ]
  },
  {
    path: 'truck-type',
    children: [
      {
        path: '',
        component: TruckTypeListComponent,
        resolve: {
          truckTypes: TruckTypeListResolver
        }
      },
      {
        path: 'add',
        component: AddOrEditTruckTypeComponent
      },

      {
        path: ':id/edit',
        component: AddOrEditTruckTypeComponent,
        resolve: {
          truckType: TruckTypeResolver
        }
      }
    ]
  },
  {
    path: 'registration-activities',
    children: [
      {
        path: '',
        component: RegistrationActivityListComponent,
        resolve: { registrationActivities: RegistrationActivityListResolver }
      },
      {
        path: 'add',
        component: AddOrEditRegistrationActivityComponent
      },

      {
        path: ':id/edit',
        component: AddOrEditRegistrationActivityComponent,
        resolve: {
          registrationActivity: RegistrationActivityResolver
        }
      }
    ]
  },
  {
    path: 'product-registration-kpis',
    children: [
      {
        path: '',
        component: ProductRegistrationKpisListComponent,
        resolve: {
          productRegistrationLeadTimes: ProductRegistrationKpiListResolver
        }
      },
      {
        path: 'add',
        component: AddOrEditProductRegistrationKpisComponent
      },
      {
        path: ':id/edit',
        component: AddOrEditProductRegistrationKpisComponent,
        resolve: {
          productRegistrationLeadTime: ProductRegistrationKpiResolver
        }
      }
    ]
  },
  {
    path: 'registration-activity-transition',
    children: [
      {
        path: '',
        component: RegistrationActivityTransitionListComponent,
        resolve: {
          registrationActivityTransitions: RegistrationActivityTransitionListResolver
        }
      },
      {
        path: 'add',
        component: AddOrEditRegistrationActivityTransitionComponent,
        resolve: {
          registrationActivityTransitions: RegistrationActivityTransitionListResolver
        }
      },
      {
        path: ':id/edit',
        component: AddOrEditRegistrationActivityTransitionComponent,
        resolve: {
          currentActivity: RegistrationActivityTransitionResolver,
          registrationActivityTransitions: RegistrationActivityTransitionListResolver
        }
      }
    ]
  },
  {
    path: 'product-categories',
    children: [
      {
        path: '',
        component: ProductCategoryListComponent,
        resolve: {
          productCategories: ProductCategoryListResolver
        }
      },
      {
        path: 'add',
        component: AddOrEditProductCategoryComponent
      },
      {
        path: ':id/edit',
        component: AddOrEditProductCategoryComponent,
        resolve: {
          productCategory: ProductCategoryResolver
        }
      }
    ]
  },
  {
    path: 'approval-activities',
    children: [
      {
        path: '',
        component: ApprovalActivityListComponent,
        resolve: {
          approvalActivities: ApprovalActivitiesListResolver
        }
      },
      {
        path: 'add',
        component: AddOrEditApprovalActivityComponent,
        resolve: {
          lookups: ApprovalActivityLookupsResolver
        }
      },
      {
        path: ':id/edit',
        component: AddOrEditApprovalActivityComponent,
        resolve: {
          approvalActivity: ApprovalActivityResolver,
          lookups: ApprovalActivityLookupsResolver
        }
      }
    ]
  },
  {
    path: 'approval-activity-transitions',
    children: [
      {
        path: '',
        component: ApprovalActivityTransitionListComponent,
        resolve: { activityTransitions: ApprovalActivityTransitionListResolver }
      },
      {
        path: 'add',
        component: AddOrEditApprovalActivityTransitionComponent,
        resolve: { activities: ApprovalActivitiesListResolver }
      },
      {
        path: ':id/edit',
        component: AddOrEditApprovalActivityTransitionComponent,
        resolve: {
          activities: ApprovalActivitiesListResolver,
          currentActivity: ApprovalActivityTransitionResolver
        }
      }
    ]
  },
  {
    path: 'approval-kpis',
    children: [
      {
        path: '',
        component: ApprovalKpisListComponent,
        resolve: { approvalLeadTimes: ApprovalKpisListResolver }
      },
      {
        path: 'add',
        component: AddOrEditApprovalKpisComponent
      },
      {
        path: ':id/edit',
        component: AddOrEditApprovalKpisComponent,
        resolve: { approvalLeadTime: ApprovalKpiResolver }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminPortalConfigsRouting {}
