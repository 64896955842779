export enum EntityType {
  FreePLEntities = 'FreePL Entities',
  CustomerEntities = 'Customer Entities',
  Supplier = 'supplier',
  TruckingPricelist = 'Trucking Pricelist',
  DomesticTruckingPricelist = 'Domestic Trucking Pricelist',
  CustomsClearancePricelist = 'Customs Clearance Pricelist',
  Registration = 'Registration',
  Approval = 'Approval'
}
