import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IsOddLength } from 'src/app/shared/services/validators/odd-number.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { HSCodeService } from 'src/app/admin-portal-configs/services/hsCode.service';
import { HSCode } from 'src/app/shared/models/shipment/hsCodes.model';
import { AppTranslationService } from 'src/app/shared/services/app-translation.service';
 
@Component({
  selector: 'app-add-edit-hs-code',
  templateUrl: './add-edit-hs-code.component.html',
  styleUrls: ['./add-edit-hs-code.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddEditHsCodeComponent implements OnInit {
  constructor(
    private fb: UntypedFormBuilder,
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private hsCodeService: HSCodeService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddEditHsCodeComponent>
  ) {}

  hsCode: HSCode;
  isEditingMode: boolean = false;
  hsCodeForm: UntypedFormGroup;
  isSaving: boolean = false;
  isDuplicatedHSCode: boolean = false;

  ngOnInit(): void {
    if (this.data !== undefined && this.data?.hsCode !== undefined) {
      this.hsCode = this.data['hsCode'];
      this.isEditingMode = true;
    }
    this.intializeForm();
  }

  intializeForm() {
    this.hsCode = this.hsCode || ({} as HSCode);
    this.hsCodeForm = this.fb.group({
      code: [
        this.hsCode.code,
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(10),
          Validators.pattern('[0-9]*'),
          IsOddLength()
        ]
      ]
    });
  }

  save() {
    this.isSaving = true;
    const formValues = this.hsCodeForm.value as HSCode;
    if (!this.hsCodeForm.valid) {
      this.alertService.error('Invalid Form!');
      return;
    }
    if (this.isEditingMode) {
      this.updateRequest(formValues);
    } else {
      this.addNewRequest(formValues);
    }
  }

  updateRequest(hsCode: HSCode) {
    hsCode.id = this.hsCode.id;
    this.hsCodeService.updateHSCode(hsCode).subscribe(
      data => {
        this.alertService.success('Success!');
        this.hsCodeService.hsCodesUpdated.emit(hsCode);
        this.dialogRef.close();
        return true;
      },
      error => {
        this.handleError(error);
      }
    );
    return false;
  }

  addNewRequest(hsCode: HSCode) {
    this.hsCodeService.addNewHSCode(hsCode).subscribe(
      data => {
        if (!data || data.length <= 0) {
          this.isDuplicatedHSCode = true;
          this.isSaving = false;
        } else {
          this.alertService.success('Success!');
          this.hsCodeService.hsCodesAdded.emit(data);
          this.dialogRef.close();
        }
        return true;
      },
      error => {
        this.handleError(error);
      }
    );
    return false;
  }

  cancel() {
    this.dialogRef.close();
  }

  handleError(error) {
    if (error === 409) {
      this.isDuplicatedHSCode = true;
    } else this.alertService.error('Error!');
    this.isSaving = false;
    return false;
  }
}
