import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AlertService,
  MessageSeverity
} from 'src/app/shared/services/alert.service';
import { FormsHelperService } from 'src/app/shared/services/forms-helper.service';
import { Carrier } from '../../../../shared/models/carrier.model';
import { CarrierService } from '../../../services/carrier.service';

@Component({
  selector: 'app-add-or-edit-carrier',
  templateUrl: './add-or-edit-carrier.component.html',
  styleUrls: ['./add-or-edit-carrier.component.scss']
})
export class AddOrEditCarrierComponent implements OnInit {
  carrierForm: UntypedFormGroup;
  carrier: Carrier;
  isSaving: boolean = false;
  duplicateCarrier: boolean = false;
  isNewCarrier: boolean = false;
  typeRequired: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private alertService: AlertService,
    private carrierService: CarrierService,
    private route: ActivatedRoute,
    private formHeplerService: FormsHelperService
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.carrier = data['carrier'];
    });

    this.initForm();
  }
  initForm() {
    this.isNewCarrier = this.carrier == null;
    this.carrier = this.carrier || ({} as Carrier);
    this.carrierForm = this.fb.group({
      name: [this.carrier.name, [Validators.required]],
      carrierAbbreviatedName: [
        this.carrier.carrierAbbreviatedName,
        [Validators.required]
      ],
      isAirCarrier: [this.carrier.isAirCarrier],
      isOceanCarrier: [this.carrier.isOceanCarrier],
      isLandCarrier: [this.carrier.isLandCarrier],
      iataicaoCode: [
        this.carrier.iataicaoCode,
        this.carrier.isAirCarrier ? Validators.required : ''
      ],
      scacCode: [
        this.carrier.scacCode,
        this.carrier.isOceanCarrier ? Validators.required : ''
      ],
      airlineCode: [
        this.carrier?.airlineCode,
        this.carrier.isAirCarrier ? Validators.required : ''
      ]
    });
  }

  save() {
    const formVal = this.carrierForm.value;

    if (!this.validateForm(formVal)) {
      this.alertService.error('Invalid Form!');
      return;
    }

    if (this.isNewCarrier) {
      this.addNewRequest(formVal);
    } else {
      this.updateRequest(formVal);
    }
  }
  private validateForm(formVal: any) {
    formVal.isAirCarrier = formVal.isAirCarrier || false;
    formVal.isOceanCarrier = formVal.isOceanCarrier || false;
    formVal.isLandCarrier = formVal.isLandCarrier || false;

    const validType =
      formVal.isAirCarrier || formVal.isLandCarrier || formVal.isOceanCarrier;

    this.typeRequired = !validType;
    return validType && this.carrierForm.valid;
  }

  updateRequest(formVal: any) {
    this.carrierService.updateCarrier(this.carrier.id, formVal).subscribe(
      data => {
        this.saveSuccessHelper(data);
      },
      error => {
        this.saveFailedHelper(error);
      }
    );
  }

  addNewRequest(formVal: any) {
    this.carrierService.addNewCarrier(formVal).subscribe(
      data => {
        this.saveSuccessHelper(data);
      },
      error => {
        this.saveFailedHelper(error);
      }
    );
  }

  private saveSuccessHelper(carrier?: Carrier) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();

    if (this.isNewCarrier) {
      this.alertService.showMessage(
        'Success',
        `Carrier \"${carrier.name}\" was created successfully`,
        MessageSeverity.success
      );
    } else {
      this.alertService.showMessage(
        'Success',
        `Changes to carrier \"${carrier.name}\" were saved successfully`,
        MessageSeverity.success
      );
    }

    this.resetForm();
    this.router.navigate(['/settings/carriers']);
  }

  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    if (error == '409') {
      this.duplicateCarrier = true;
      return;
    }
    this.alertService.showStickyMessage(
      'Save Error',
      'The below errors occured whilst saving your changes:',
      MessageSeverity.error,
      error
    );
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  cancel() {
    this.resetForm();

    this.alertService.showMessage(
      'Cancelled',
      'Operation cancelled by user',
      MessageSeverity.default
    );

    this.router.navigate(['/settings/carriers']);
  }

  resetForm() {
    this.carrierForm.reset();
  }

  validateFields() {
    if (!this.isAirCarrier) {
      this.carrierForm.get('iataicaoCode').setValue(null);
    }
    if (!this.isOceanCarrier) {
      this.carrierForm.get('scacCode').setValue(null);
    }
    
    this.toggleValidation(this.isAirCarrier, 'iataicaoCode');
    this.toggleValidation(this.isOceanCarrier, 'scacCode');
  }

  get isAirCarrier() {
    return this.carrierForm.get('isAirCarrier')?.value;
  }
  get isOceanCarrier() {
    return this.carrierForm.get('isOceanCarrier')?.value;
  }
  get isLandCarrier() {
    return this.carrierForm.get('isLandCarrier')?.value;
  }

  toggleValidation(value, controlName) {
    if (value) {
      this.formHeplerService.addRequiredValidator(
        this.carrierForm,
        controlName
      );
    } else {
      this.formHeplerService.clearValidator(this.carrierForm, controlName);
    }
  }
}
