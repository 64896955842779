<mat-expansion-panel hideToggle="true" #productsTableExpansionPanel>
  <mat-expansion-panel-header class="products-table-list-header">
    <mat-panel-description class="panel-description">
      <div class="products-row-container col-12 pl-0 pr-0">
        <div
          class="column-content cell-padding product-name col-2"
          *ngIf="isProductRegistration"
        >
          {{ partNumber }}
        </div>

        <div
          class="column-content product-name cell-padding col-2"
          [ngbPopover]="productName"
          placement="bottom-left"
          triggers="mouseenter:mouseleave"
          [disablePopover]="productName?.length < 40"
          *ngIf="isApproval"
        >
          {{ productName | slice: 0:40 }}
        </div>

        <div
          class="column-content cell-padding col-3"
          [ngbPopover]="productDescription"
          placement="bottom-left"
          triggers="mouseenter:mouseleave"
          [disablePopover]="productDescription?.length < 40"
          [class]="{ 'product-description-cell': isProductRegistration }"
        >
          {{ productDescription | slice: 0:40 }}
        </div>

        <div
          class="column-content cell-padding col-2"
          *ngIf="isApproval"
          [ngStyle]="{
            color: statusColor
          }"
        >
          {{ status }}
        </div>

        <div
          class="column-content cell-padding col-2"
          *ngIf="isProductRegistration"
        >
          {{ documentsCount }}
        </div>

        <div
          (click)="toggleShowInput()"
          class="cell-padding cursor-pointer col-2 notes-cell"
          *ngIf="isProductRegistration"
        >
          <span
            [ngClass]="{ notes: product?.notes == null }"
            *ngIf="!notesUpdate?.showInput"
          >
            {{ product.notes || "No notes added." }}
          </span>
          <input
            *ngIf="notesUpdate?.showInput"
            maxlength="180"
            (click)="$event.stopPropagation()"
            (keyup.enter)="updateNotes()"
            (keyup.escape)="toggleShowInput()"
            [(ngModel)]="notesUpdate.insertedNotes"
            class="notes-input"
          />
        </div>

        <div class="actions col-2 cell-padding actions-cell">
          <div
            class="action-icon edit-action cursor-pointer"
            (click)="navigateToProductDetails(product?.productId)"
            *ngIf="!isDisabled"
          >
            <svg-icon name="editQuote"></svg-icon>
          </div>

          <div
            class="action-icon delete-action cursor-pointer"
            (click)="deleteProduct()"
            *ngIf="!isDisabled && (canDeleteProductFromRegistration && isProductRegistration)"
          >
            <svg-icon name="delete"></svg-icon>
          </div>
        </div>

        <div
          class="col-1"
          *ngIf="isProductRegistration && variants?.length > 0"
        >
          <div class="expansion-arrow-content cursor-pointer">
            <svg-icon
              class="expansion-arrow"
              name="ExpansionArrow"
              [class]="
                productsTableExpansionPanel.expanded
                  ? 'expansion-arrow-expanded'
                  : ''
              "
            >
            </svg-icon>
          </div>
        </div>
      </div>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <ng-template matExpansionPanelContent>
    <app-product-variants-list
      [variants]="variants"
    ></app-product-variants-list>
  </ng-template>
</mat-expansion-panel>
