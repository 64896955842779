<div>
  <div
    [ngClass]="{
      'warning-badge-thin': thinBar,
      'warning-badge': !thinBar
    }"
  >
    <p class="warning-message">
      <svg-icon [name]="'alertMark'"></svg-icon>
      {{ message || "Error" }}
    </p>
  </div>
</div>
