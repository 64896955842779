<form
  [formGroup]="currencyForm"
  class="mt-3 mb-3"
  id="currencyForm"
  (ngSubmit)="save()"
>
  <div class="modal-header">
    <h4 class="  float-left">
      <i class="fa fa-shield"></i>
      {{
        isEditingMode
          ? ("config.management.Currencies.Edit" | translate: isEditingMode)
          : ("config.management.Currencies.New" | translate)
      }}
    </h4>
    <button
      type="button"
      class="close float-right"
      title="Close"
      (click)="dialogRef.close()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div *ngIf="isDoublicatedCurrency">
    <app-error-badge
      [thinBar]="true"
      message="An entry with the same Name or Code already exists."
      class="warning-badge"
    ></app-error-badge>
  </div>

  <div class="form-group">
    <label for="name">{{ "config.management.Name" | translate }}</label>
    <input
      formControlName="name"
      [ngClass]="{
        'is-invalid':
          currencyForm.get('name').errors && currencyForm.get('name').touched
      }"
      type="text"
      class="form-control"
      id="name"
      placeholder="{{
        'config.management.Currencies.NamePlaceHolder' | translate
      }}"
    />
    <small
      *ngIf="
        currencyForm.get('name').touched &&
        currencyForm.get('name').hasError('required')
      "
      class="form-text text-danger"
      >{{ "Please enter currency name." }}</small
    >
  </div>
  <div class="form-group">
    <label for="code">{{
      "config.management.Currencies.Code" | translate
    }}</label>
    <input
      formControlName="code"
      [ngClass]="{
        'is-invalid':
          currencyForm.get('code').errors && currencyForm.get('code').touched
      }"
      type="text"
      class="form-control"
      id="code"
      placeholder="{{
        'config.management.Currencies.CodePlaceHolder' | translate
      }}"
    />
    <small
      *ngIf="
        currencyForm.get('code').touched &&
        currencyForm.get('code').hasError('required')
      "
      class="form-text text-danger"
      >{{ "Please enter currency code." }}</small
    >
  </div>
  <div class="form-group">
    <label for="name">{{
      "config.management.Currencies.Symbol" | translate
    }}</label>
    <input
      formControlName="symbol"
      [ngClass]="{
        'is-invalid':
          currencyForm.get('symbol').errors &&
          currencyForm.get('symbol').touched
      }"
      type="text"
      class="form-control"
      id="symbol"
      placeholder="{{
        'config.management.Currencies.SymbolPlaceHolder' | translate
      }}"
    />
    <small
      *ngIf="
        currencyForm.get('symbol').touched &&
        currencyForm.get('symbol').hasError('required')
      "
      class="form-text text-danger"
      >{{ "Please enter currency symbol." }}</small
    >
  </div>
  <div class="form-group">
    <label for="egpExchangeRate">{{
      "config.management.Currencies.ExchangeRate" | translate
    }}</label>
    <input
      formControlName="egpExchangeRate"
      [ngClass]="{
        'is-invalid':
          currencyForm.get('egpExchangeRate').errors &&
          currencyForm.get('egpExchangeRate').touched
      }"
      type="number"
      class="form-control"
      id="egpExchangeRate"
      placeholder="{{
        'config.management.Currencies.EgpExchangeRate' | translate
      }}"
    />
    <small
      *ngIf="
        currencyForm.get('egpExchangeRate').touched &&
        currencyForm.get('egpExchangeRate').hasError('required')
      "
      class="form-text text-danger"
      >{{ "Please enter exchange rate." }}</small
    >
    <small
      *ngIf="
        !currencyForm.get('egpExchangeRate').hasError('required') &&
        currencyForm.get('egpExchangeRate').touched &&
        currencyForm.get('egpExchangeRate').errors
      "
      class="form-text text-danger"
      >{{ "Please enter a vaild exchange rate." }}</small
    >
  </div>
  <div class="col-sm-12 form-group">
    <div ngPreserveWhitespaces class="float-right">
      <button
        type="submit"
        class="btn btn-primary"
        [disabled]="isSaving || !currencyForm.valid"
      >
        <i *ngIf="!isSaving" class="fa fa-save"></i>
        <i *ngIf="isSaving" class="fa fa-circle-o-notch fa-spin"></i>
        {{
          isSaving
            ? ("config.management.Currencies.Editor.Saving" | translate)
            : ("config.management.Currencies.Editor.Save" | translate)
        }}
      </button>
      <button type="button" class="btn btn-danger" (click)="cancel()">
        <i class="fa fa-times"></i>
        {{ "config.management.Currencies.Editor.Cancel" | translate }}
      </button>
    </div>
  </div>
</form>
