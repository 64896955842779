import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from 'src/app/shared/services/alert.service';
import { DocumentService } from 'src/app/shared/services/document.service';
import { ShipmentsService } from '../../../shipments/shipments.service';
import { ProductRegistrationService } from 'src/app/product-registration/services/product-registration.service';
import { ModuleName } from '../../models/enums/module-name.enum';
import { SupplierService } from 'src/app/supplier/services/supplier.service';
import { ProductService } from 'src/app/product-library/services/product.service';
import { ApprovalService } from 'src/app/create-approval/services/approval.service';
import { DocumentTypeEnum } from '../../models/enums/document-type.enum';

@Component({
  selector: 'app-delete-document',
  templateUrl: './delete-document.component.html',
  styleUrls: ['./delete-document.component.scss']
})
export class DeleteDocumentComponent implements OnInit {
  notes: string;
  error: boolean = false;
  documentSqlId: number;
  parentDocumentSqlId: number = null;
  module: ModuleName;
  parentId: string;
  documentTypeName: string;

  constructor(
    private alertService: AlertService,
    private shipmentsService: ShipmentsService,
    private documentService: DocumentService,
    private productRegistrationService: ProductRegistrationService,
    private productLibraryService: ProductService,
    private supplierService: SupplierService,
    private approvalService: ApprovalService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DeleteDocumentComponent>
  ) {}

  ngOnInit(): void {
    this.documentSqlId = this.data['documentSqlId'];
    this.parentId = this.data['parentId'];
    this.module = this.data['module'];

    if (!this.parentDocumentSqlId) {
      this.parentDocumentSqlId = this.data['parentDocumentSqlId'];
    }

    if (!this.documentTypeName) {
      this.documentTypeName = this.data['documentTypeName'];
    }
  }

  cancel(isDeleted: boolean) {
    this.documentService.closeDocumentRemovalPopUp(
      isDeleted,
      this.documentSqlId,
      this.notes,
      new Date(),
      this.parentDocumentSqlId
    );

    this.dialogRef.close();
  }

  deleteDocument() {
    this.error = true;

    if (!this.notes || !this.parentId) {
      return;
    }

    if (this.module === ModuleName.Shipment) {
      this.error = false;
      this.shipmentsService
        .deleteDocument(
          this.parentId,
          this.documentSqlId,
          this.notes,
          this.parentDocumentSqlId
        )
        .subscribe(res => {
          this.cancel(true);
          this.alertService.success('Document successfully deleted.');
          this.shipmentsService.shipmentUpdated.emit();
        });
    } else if (this.module === ModuleName.Registration) {
      this.error = false;
      this.productRegistrationService
        .deleteOldDocument(
          this.parentId,
          this.documentSqlId,
          this.notes,
          this.documentTypeName
        )
        .subscribe(res => {
          this.cancel(true);
          this.alertService.success('Document successfully deleted.');
          let document =
            this.documentTypeName == DocumentTypeEnum.TechnicalFile
              ? res.technicalFile
              : res.registrationLicense;
          this.productRegistrationService.onProductRegistrationFilesUpdated.emit(
            {
              document: document,
              documentTypeName: this.documentTypeName
            }
          );
        });
    } else if (this.module === ModuleName.Supplier) {
      this.error = false;
      this.supplierService
        .deleteSupplierDocument(
          this.parentId,
          this.documentSqlId,
          this.notes,
          this.parentDocumentSqlId
        )
        .subscribe(res => {
          this.cancel(true);
          this.alertService.success('Document successfully deleted.');
          this.supplierService.supplier = res;
          this.supplierService.supplierUpdated.emit(res);
        });
    } else if (this.module === ModuleName.ProductLibrary) {
      this.error = false;
      this.productLibraryService
        .deleteDocument(
          this.parentId,
          this.documentSqlId,
          this.notes,
          this.parentDocumentSqlId
        )
        .subscribe(res => {
          this.cancel(true);
          this.alertService.success('Document successfully deleted.');
          this.productLibraryService.productLibraryRequestUpdated.emit(res);
        });
    } else if (this.module === ModuleName.Approval) {
      this.error = false;
      this.approvalService
        .deleteApprovalDocument(
          this.parentId,
          this.documentSqlId,
          this.notes,
          this.parentDocumentSqlId
        )
        .subscribe(() => {
          this.cancel(true);
          this.alertService.success('Document successfully deleted.');
          this.approvalService.onApprovalChange.emit(true);
        });
    } else if (this.module === ModuleName.CompanyProfile) {
      this.error = false;
      this.documentService
        .deleteDocument(this.documentSqlId, this.notes)
        .subscribe(data => {
          this.cancel(true);
          this.alertService.success('Document successfully deleted.');
          this.documentService.documentDeleted.emit();
        });
    }
  }
}
