import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-or-edit-country',
  templateUrl: './add-or-edit-country.component.html',
  styleUrls: ['./add-or-edit-country.component.scss']
})
export class AddOrEditCountryComponent implements OnInit {
  pageTitle: string = 'Add Country Details';
  countryForm: UntypedFormGroup;
  isInvalidForm: boolean;

  constructor(private fb: FormBuilder, private router: Router) {}

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.countryForm = this.fb.group({
      country: [null, [Validators.required]],
      code: [null, [Validators.required]],
      lat: [null, [Validators.required]],
      lng: [null, [Validators.required]],
      isReference: [null, [Validators.required]],
      isLandFreight: [null, [Validators.required]]
    });
  }

  saveORCancel(isSave: boolean) {
    if (isSave) {
      this.save();
    } else {
      this.cancel();
    }
  }

  save() {
    this.isInvalidForm = false;
    this.countryForm.markAllAsTouched();
    if (this.countryForm.invalid) {
      this.isInvalidForm = true;
    }
  }

  cancel() {
    this.router.navigate(['../countries']);
  }

  validateLatLngInput(event: any): void {
    const pattern = /^[0-9.-]*$/;
    const input = event.target.value;

    if (!pattern.test(input)) {
      event.target.value = input.replace(/[^0-9.-]/g, '');
    }
  }
}
