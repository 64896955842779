import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EntityType } from 'src/app/createShipment/models/enums/entity-type.enum';
import { StatusChangeRequest } from 'src/app/shared/models/status-change-request.model';
import { ProductRegistrationService } from '../../../product-registration/services/product-registration.service';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-cancel-entity',
  templateUrl: './cancel-entity.component.html',
  styleUrls: ['./cancel-entity.component.scss']
})
export class CancelEntityComponent implements OnInit {
  entityName: string;
  reason: string;
  isLoading: boolean;
  reasonError: boolean;
  minLength: number = 5;
  productId: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CancelEntityComponent>,
    private productRegistrationService: ProductRegistrationService,
    private alertService: AlertService,
  ) {}

  ngOnInit(): void {
    this.entityName = this.data['entityName'];
    this.productId = this.data['productId'];
  }

  confirm(isCancel: boolean) {
    if (this.isLoading) {
      return;
    }

    if (isCancel) {
      if (!this.reason || this.reason?.trim()?.length < this.minLength) {
        this.reasonError = true;
        return;
      }
      if (this.productId) {
        this.isLoading = true;
        let statusChangeRequest: StatusChangeRequest = {
          id: this.productId,
          reason: this.reason
        };
        this.productRegistrationService
          .cancelProductRegistration(statusChangeRequest)
          .subscribe(() => {
            this.productRegistrationService.productRegistrationRequestUpdated.emit();
            this.alertService.success('Request successfully cancelled.');
            this.dialogRef.close(this.reason);
          });
      }
    }
    else {
      this.dialogRef.close(this.reason);
    }
    
  }
    

  get isProductRegistrationRequestEntity(): boolean {
    return this.entityName == EntityType.Registration;
  }

  get errorMesage(): string {
    return `Please enter at least 5 characters to submit.`;
  }

  get isApprovalEntity(): boolean {
    return this.entityName == EntityType.Approval;
  }

  get title(): string {
    if (this.isProductRegistrationRequestEntity || this.isApprovalEntity) {
      return 'Request';
    } else {
      return 'Shipment';
    }
  }
}
