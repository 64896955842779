import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { LeavingPageNotificationComponent } from '../components/leaving-page-notification/leaving-page-notification.component';
import { CreateShipmentService } from '../../createShipment/createShipment.service';
import { CreateProductRegistrationService } from 'src/app/create-product-registration/service/create-product-registration.service';
import { ProductRegistration } from 'src/app/create-product-registration/models/product-registration.model';
import { ShipmentHelpersService } from 'src/app/shipments/services/shipment-helpers.service';

@Injectable({
  providedIn: 'root'
})
export class PreventLeavingPageGuard implements CanDeactivate<any> {
  canLeave: boolean = false;
  productRegistration: ProductRegistration;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private shipmentHelperService: ShipmentHelpersService,
    private createShipmentService: CreateShipmentService,
    private createProductRegistrationService: CreateProductRegistrationService
  ) {}

  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (nextState.url == '/login' || this.isSummarySubmit(currentRoute)) {
      this.canLeave = true;
    }

    if (this.canLeave) {
      this.canLeave = false;
      this.createShipmentService.isSubmitted = false;
      this.createProductRegistrationService.isProductRegistrationSubmitted = false;
      return true;
    }

    var dialogRef = this.dialog.open(LeavingPageNotificationComponent, {
      hasBackdrop: true,
      width: '600px',
      maxHeight: '675px',
      data: {
        message: 'Are you sure you want to leave this page?'
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      this.canLeave = data;
      if (data) {
        this.shipmentHelperService.totalUserDraftShipmentsNumber = null;
        this.router.navigateByUrl(nextState.url);
      }
    });

    return of(this.canLeave);
  }

  private isSummarySubmit(currentRoute: any): boolean {
    const isInCreateShipmentModule = currentRoute._urlSegment?.segments?.find(
      s => s.path === 'createshipment'
    );
    const isInEditShipmentModule = currentRoute._urlSegment?.segments?.find(
      s => s.path === 'edit-chooseservices'
    );

    const isInProductRegistrationModule = currentRoute._urlSegment?.segments?.find(
      s => s.path === 'create-product-registration'
    );

    if (isInCreateShipmentModule && !isInEditShipmentModule) {
      return this.createShipmentService?.isSubmitted;
    } else if (isInProductRegistrationModule) {
      return this.createProductRegistrationService
        .isProductRegistrationSubmitted;
    }

    return true;
  }
}
