<div class="row p-3">
  <br />
  <div class="col-lg-12 col-md-12 col-sm-12 pb-1 mx-0">
    <div class="header">
      <app-page-header
        [title]="isEditRoute ? 'Edit Supplier Details' : 'Add Supplier Details'"
      ></app-page-header>
      <div class="note">
        (<span class="field-mandatory">*</span>) means that this field is
        mandatory
      </div>
    </div>

    <form [formGroup]="supplierForm" id="supplierForm" (ngSubmit)="save()">
      <div class="full-logo">
        <div class="supplier-logo" (click)="onUploadClick()">
          <span
            *ngIf="!supplier.logo && !isLogoUploaded"
            class="supplier-logo no-picture"
          >
            <div class="no-logo">Logo</div>
          </span>
          <div *ngIf="selectedImage" class="supplier-logo uploded-logo">
            <div><img [src]="selectedImage" /></div>
          </div>
          <input
            type="file"
            (change)="onChangeLogo($event)"
            accept="image/*"
            style="display: none;"
            id="logo"
            formControlName="logo"
            #fileInput
          />
          <svg-icon class="camera-icon" name="camera-navy"></svg-icon>
        </div>
      </div>
      <div class="form-body _row">
        <div class="field-container first-row">
          <div class="input-field-container">
            <label class="field-title" for="name"
              >{{ "supplier.SupplierName" | translate
              }}<span class="text-error">*</span></label
            >
            <input
              formControlName="name"
              [ngClass]="{
                'is-invalid':
                  supplierForm.get('name').errors &&
                  supplierForm.get('name').touched
              }"
              type="text"
              class="form-control field-input"
              id="name"
              maxlength="30"
            />
          </div>

          <div class="input-field-container">
            <label class="field-title" for="officialName"
              >{{ "supplier.OfficialName" | translate
              }}<span class="text-error">*</span></label
            >
            <input
              formControlName="officialName"
              [ngClass]="{
                'is-invalid':
                  supplierForm.get('officialName').errors &&
                  supplierForm.get('officialName').touched
              }"
              type="text"
              class="form-control field-input"
              id="officialName"
              maxLength="30"
            />
          </div>
        </div>

        <div class="field-container">
          <div class="input-field-container">
            <label class="field-title" for="registrationNumber">{{
              "supplier.RegistrationNumber" | translate
            }}</label>
            <input
              formControlName="registrationNumber"
              [ngClass]="{
                'is-invalid':
                  supplierForm.get('registrationNumber').errors &&
                  supplierForm.get('registrationNumber').touched
              }"
              type="text"
              class="form-control field-input"
              id="registrationNumber"
              maxlength="30"
            />
          </div>

          <div class="input-field-container">
            <label class="field-title" for="vatNumber">{{
              "supplier.VATNumber" | translate
            }}</label>
            <input
              formControlName="vatNumber"
              [ngClass]="{
                'is-invalid':
                  supplierForm.get('vatNumber').errors &&
                  supplierForm.get('vatNumber').touched
              }"
              type="text"
              class="form-control field-input"
              id="vatNumber"
              maxLength="30"
            />
          </div>
        </div>

        <div class="field-container">
          <div class="input-field-container">
            <label class="field-title" for="phoneNumber">{{
              "supplier.Phone" | translate
            }}</label>
            <input
              formControlName="phoneNumber"
              [ngClass]="{
                'is-invalid':
                  supplierForm.get('phoneNumber').errors &&
                  supplierForm.get('phoneNumber').touched
              }"
              type="text"
              class="form-control field-input"
              id="phoneNumber"
              maxlength="15"
            />
          </div>

          <div class="input-field-container">
            <label class="field-title" for="email">{{
              "supplier.Email" | translate
            }}</label>
            <input
              formControlName="email"
              [ngClass]="{
                'is-invalid':
                  supplierForm.get('email').errors &&
                  supplierForm.get('email').touched
              }"
              type="text"
              class="form-control field-input"
              id="email"
              maxlength="30"
            />
          </div>
        </div>

        <div class="field-container">
          <div class="input-field-container">
            <label class="field-title" for="address"
              >{{ "supplier.Address" | translate
              }}<span class="text-error">*</span></label
            >
            <input
              formControlName="address"
              [ngClass]="{
                'is-invalid':
                  supplierForm.get('address').errors &&
                  supplierForm.get('address').touched
              }"
              type="text"
              class="form-control field-input address-field"
              id="address"
              maxlength="100"
            />
          </div>
        </div>

        <div class="field-container">
          <div class="input-field-container">
            <div>
              <label class="field-title" for="country">{{
                "route.Country" | translate
              }}</label>
              <ng-select
                class="form-control field-input select-text custom-scrollbar country-field"
                appearance="outline"
                [items]="countries"
                placeholder="{{ 'route.SelectCountry' | translate }}"
                formControlName="country"
                id="country"
                (change)="onChangeCountry()"
                [searchFn]="onCountrySearch"
                [virtualScroll]="true"
                [clearable]="false"
                bindLabel="name"
              >
                <ng-template
                  ng-option-tmp
                  let-item="item"
                  let-index="index"
                  let-search="searchTerm"
                >
                  <mat-radio-button
                    [value]="item"
                    [checked]="isFormControlSelected(item.name, 'country')"
                  >
                    <label class="value">{{ item.name }}</label>
                  </mat-radio-button>
                </ng-template>
              </ng-select>
            </div>
          </div>

          <div class="input-field-container">
            <div>
              <label class="field-title" for="city">{{
                "route.City" | translate
              }}</label>
              <ng-select
                appearance="outline"
                [items]="cities"
                class="form-control field-input select-text city-field"
                formControlName="city"
                id="city"
                (change)="onChangeCity()"
                [searchFn]="onCitySearch"
                [virtualScroll]="true"
                placeholder="{{ 'route.SelectCity' | translate }}"
                [readonly]="!supplierForm.get('country').value"
                [clearable]="false"
                bindLabel="name"
              >
                <ng-template
                  ng-option-tmp
                  let-item="item"
                  let-index="index"
                  let-search="searchTerm"
                >
                  <mat-radio-button
                    [value]="item"
                    [checked]="isFormControlSelected(item.name, 'city')"
                  >
                    <label class="value ">{{ item.name }}</label>
                  </mat-radio-button>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>

        <div class="field-container">
          <div class="input-field-container">
            <label class="field-title" for="website">{{
              "supplier.Website" | translate
            }}</label>
            <input
              formControlName="website"
              [ngClass]="{
                'is-invalid':
                  supplierForm.get('website').errors &&
                  supplierForm.get('website').touched
              }"
              type="text"
              class="form-control field-input"
              id="website"
              maxlength="30"
            />
          </div>

          <div class="input-field-container">
            <label class="field-title" for="type"
              >{{ "supplier.SupplierType" | translate
              }}<span class="text-error">*</span></label
            >
            <ng-select
              class="form-control field-input"
              formControlName="type"
              id="type"
              [virtualScroll]="true"
              appearance="outline"
              [searchable]="true"
              [bindLabel]="'name'"
              [clearable]="false"
              [items]="types"
            >
              <ng-template ng-option-tmp let-item="item" let-index="index">
                <mat-radio-button
                  [value]="item"
                  [checked]="isFormControlSelected(item.name, 'type')"
                >
                  <label class="value">{{ item.name }}</label>
                </mat-radio-button>
              </ng-template>
            </ng-select>
          </div>
        </div>

        <div class="field-container">
          <div class="input-field-container">
            <label class="field-title" for="services"
              >{{ "supplier.ServiceName" | translate
              }}<span class="text-error">*</span></label
            >
            <ng-select
              class="form-control field-input services-ddl"
              formControlName="services"
              id="services"
              [items]="services"
              [virtualScroll]="true"
              appearance="outline"
              multiple="true"
              [searchable]="true"
              [clearable]="false"
              [bindLabel]="'name'"
            >
              <ng-template
                ng-multi-label-tmp
                let-items="items"
                let-clear="clear"
              >
                <div class="selected-values">
                  <div>
                    {{ getSelectedServices(items) | slice: 0:20 }}
                    <span *ngIf="getSelectedServices(items).length > 20"
                      >...</span
                    >
                  </div>
                </div>
              </ng-template>

              <ng-template
                ng-option-tmp
                let-item="item"
                let-item$="item$"
                let-index="index"
              >
                <div class="d-flex align-items-center">
                  <app-checkbox
                    id="item-{{ index }}"
                    [value]="'asdasd'"
                    [checked]="item$.selected"
                  ></app-checkbox>
                  <div class="mx-1"></div>
                  <label class="value">{{ item?.name }}</label>
                </div>
              </ng-template>
            </ng-select>
          </div>

          <div class="input-field-container">
            <label class="field-title" for="status"
              >{{ "supplier.Status" | translate
              }}<span class="text-error">*</span></label
            >
            <ng-select
              class="form-control field-input"
              formControlName="status"
              id="status"
              [virtualScroll]="true"
              appearance="outline"
              [items]="statuses"
              [searchable]="false"
              [bindLabel]="'name'"
            >
              <ng-template ng-option-tmp let-item="item" let-index="index">
                <mat-radio-button
                  [value]="item"
                  [checked]="isFormControlSelected(item.name, 'status')"
                >
                  <label class="value ">{{ item.name }}</label>
                </mat-radio-button>
              </ng-template>
            </ng-select>
          </div>
        </div>
      </div>

      <div class="row buttons">
        <div class="col-12">
          <div class="d-flex justify-content-end">
            <div class="form-btn cancel-btn" (click)="cancel()">
              {{ "forms.Cancel" | translate }}
            </div>
            <button type="submit" class="form-btn save-btn">
              {{ "forms.Save" | translate }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
