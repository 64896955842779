import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ProductRegistrationDetailsComponent } from '../../pages/product-registration-details/product-registration-details.component';
import { ProductRegistrationService } from '../../services/product-registration.service';

@Component({
  selector: 'app-resume-product-registration-popup',
  templateUrl: './resume-product-registration-popup.component.html',
  styleUrls: ['./resume-product-registration-popup.component.scss']
})
export class ResumeProductRegistrationPopupComponent implements OnInit {
  productId: string;
  isLoading: boolean;
  constructor(
    private alertService: AlertService,
    private productRegistrationService: ProductRegistrationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ProductRegistrationDetailsComponent>
  ) { }

  ngOnInit() {
    this.productId = this.data['productId'];
  }

  resumeProductRegistration() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.productRegistrationService
      .resumeProductRegistration(this.productId)
      .subscribe(data => {
        this.alertService.success('Request successfully resumed.');
        this.productRegistrationService.productRegistrationRequestUpdated.emit();
        this.cancel();
      });
  }

  cancel() {
    this.dialogRef.close();
  }

}
