import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PreventUnsavedChanges } from 'src/app/createShipment/guards/prevent-unsaved-changes.guard';
import { ShipmentsService } from '../../shipments.service';

@Component({
  selector: 'app-un-saved-data-notification',
  templateUrl: './un-saved-data-notification.component.html',
  styleUrls: ['./un-saved-data-notification.component.scss']
})
export class UnSavedDataNotificationComponent implements OnInit {
  @Input() message: string = null;

  constructor(
    private shipmentService: ShipmentsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PreventUnsavedChanges>
  ) {}

  ngOnInit(): void {
    if (this.data['message']) {
      this.message = this.data['message'];
    }
  }

  cancel() {  
    this.dialogRef.close();
  }

  confirm() {
    this.shipmentService.confirmUnSavedData.emit();
  }
}
