<section class="uploadCon pr-4 pl-4 pt-4 pb-2">
  <app-page-header [title]="formTitle"></app-page-header>

  <div class="optionsCon col-12 pl-0">
    <div class="upload" [formGroup]="uploadForm">
      <h4 class="document-type-title">Document Type</h4>
      <ng-select
        name="ddl-documentType"
        [(ngModel)]="selectedDocumentType"
        [placeholder]="'Select document type'"
        (change)="selectDocumentType($event)"
        appearance="outline"
        [searchable]="false"
        [clearable]="false"
        [readonly]="types.length === 1 ? true : false"
        class="documentType"
        formControlName="otherDocumentType"
        [bindLabel]="'name'"
        [ngClass]="{
          'ng-invalid': documentTypeErrorState,
          'supplier-document-type': isSupplierParentType || companyId
        }"
      >
        <ng-option
          *ngFor="let type of types"
          [value]="type"
          [disabled]="type.isDisabled"
        >
          <div class="d-flex">
            {{ getTypeName(type.name) }}
            <span *ngIf="type.isRequired" class="required-sign">*</span>
          </div>
        </ng-option>
      </ng-select>

      <div class="mt-3" *ngIf="isSelectedOtherDocumentType">
        <input
          type="text"
          [maxlength]="30"
          placeholder="Enter document name"
          formControlName="documentOtherName"
          class="field-bg doument-other-name"
          [ngClass]="
            uploadForm.get('documentOtherName')?.hasError('required') &&
            uploadForm.get('documentOtherName')?.touched
              ? 'validation-error'
              : ''
          "
          [disabled]="selectedSingleDocumentTypeId"
        />
      </div>

      <div
        class="my-2 text-error d-flex align-items-center"
        *ngIf="isExistDocumentTypeName"
      >
        <span class="pb-1">
          <svg-icon [name]="'mark!'"></svg-icon>
        </span>
        <span class="mx-1"></span>
        <span> A document with the same name is already uploaded</span>
      </div>

      <div class="mt-3"></div>
      <div class="document-expire-date" *ngIf="!isNonExpiring">
        <div class="expire-date-header">
          <span class="header">
            Document Expiry Date
          </span>
        </div>
        <div class="expire-date-input">
          <mat-form-field
            name="frm-expiryDate"
            appearance="fill"
            class="custom-datepicker"
            [ngClass]="{
              'ng-invalid': isExpirationDateErrorState
            }"
          >
            <div class="d-flex">
              <div class="input-title mr-2">
                <span> Before</span>
              </div>
              <input
                matInput
                [matDatepicker]="datepicker"
                placeholder="dd/mm/yyyy"
                [min]="currentDate"
                formControlName="expirationDate"
                readonly
              />
            </div>
            <mat-datepicker-toggle
              matSuffix
              [for]="datepicker"
              name="dtp-expiryDate"
            >
              <svg-icon
                matDatepickerToggleIcon
                name="datePickerIcon"
              ></svg-icon>
            </mat-datepicker-toggle>
            <mat-datepicker
              #datepicker
              [panelClass]="'custom-today'"
              [calendarHeaderComponent]="matDateHeader"
            >
            </mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <h4>Add File</h4>
      <div class="my-1"></div>

      <h5>
        File types:
        <span>word, excel, pdf, image</span
        >&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;Maximum file size:
        <span>10 MB</span>
      </h5>

      <div
        (dragover)="onDragOver($event)"
        (drop)="onDropSuccess($event)"
        class="uploadFile d-flex flex-column"
        [ngClass]="{
          active: uploadForm?.get('file').value !== '',
          error: uploadError?.isError || uploadFileErrorState
        }"
      >
        <ng-container>
          <div
            class="d-flex align-items-center chosen-file"
            *ngIf="uploadForm?.get('file').value !== '' && !uploadedFile"
          >
            <div>
              <span class="name">
                {{ uploadForm?.get("file").value.name }}
              </span>
              <span class="size">
                {{
                  uploadForm?.get("file").value.size > 1024
                    ? uploadForm?.get("file").value.size / 1024 > 1024
                      ? (uploadForm?.get("file").value.size / 1024 / 1024
                          | number: "1.2-2") + "MB"
                      : (uploadForm?.get("file").value.size / 1024
                          | number: "1.2-2") + "KB"
                    : uploadForm?.get("file").value.size + "Byte"
                }}
              </span>
            </div>

            <button class="cancel" (click)="resetFileForm()">
              <svg-icon [name]="'cancel'"></svg-icon>
            </button>
          </div>
        </ng-container>

        <ng-container>
          <ng-container
            *ngIf="(!uploadedFile || !uploadedFile.id) && !isfileChosen"
          >
            <svg-icon [name]="'uploadDocument'"></svg-icon>
            <p class="drop-document-here mb-0">Drop your document here</p>
            <p
              class="underline cursor-pointer mb-0"
              (click)="uploadInput.value = ''; browseFile(uploadInput)"
            >
              <i> or browse files </i>
            </p>
          </ng-container>

          <ng-container *ngIf="showErrorState">
            <span class="text-error">Please select document type </span>
          </ng-container>

          <div *ngIf="uploadedFile">
            <div class="d-flex align-items-end justify-content-between">
              <span class="filename flex-grow-1">
                <div class="d-flex align-items-end">
                  {{ uploadedFile.fileName }}
                </div>
                <div
                  class="progressCon"
                  [ngClass]="{
                    'uploading-bar': uploadedFile.isUploading,
                    'canceled-bar': uploadedFile.isCanceled
                  }"
                >
                  <mat-progress-bar
                    *ngIf="uploadedFile.isUploading || uploadedFile.isCanceled"
                    mode="determinate"
                    [value]="uploadedFile.percentageValue"
                  ></mat-progress-bar>
                </div>
              </span>

              <button
                class="cancel"
                *ngIf="uploadedFile.isUploaded || uploadedFile.isCanceled"
                (click)="deleteUploadedFile()"
              >
                <svg-icon [name]="'delete'"></svg-icon>
              </button>

              <button
                class="cancel"
                *ngIf="uploadedFile.isUploading"
                (click)="cancelUpload(uploadedFile)"
              >
                <svg-icon [name]="'cancel'"></svg-icon>
              </button>
            </div>
          </div>
        </ng-container>
      </div>
      <div
        class="error d-flex"
        *ngIf="uploadError.isError || uploadFileErrorState"
      >
        <div class="mark-icon">
          <svg-icon name="mark!"></svg-icon>
        </div>
        {{ uploadError.msg || "Add file to be uploaded" }}
      </div>
      {{ !isProductRegistrationParentType ? uploadedFile?.isUploading : "" }}
      <div *ngIf="!isSupplierParentType">
        <h4 class="additional-notes">
          Additional Notes <span><i> (optional)</i></span>
        </h4>

        <app-input
          name="input-additionalNotes"
          [classList]="'field-bg'"
          type="text"
          placeholder="Type notes here…"
          class="note"
          formControlName="notes"
          [disabled]="disableAnyAction"
          [maxLength]="180"
        >
        </app-input>
      </div>

      <div class="d-flex align-items-center justify-content-between">
        <button
          name="btn-cancelUploadDocument"
          class="secondary-button-outline w-50"
          (click)="closeDialog()"
          [ngClass]="{
            'cancel-document-supplier': isSupplierParentType || companyId
          }"
        >
          Cancel
        </button>
        <span class="mx-2"></span>
        <button
          name="btn-addUploadDocument"
          (click)="addDocument()"
          class="primary-button w-50"
          [disabled]="isSaving"
          [ngClass]="{
            'add-document-supplier': isSupplierParentType || companyId
          }"
        >
          {{ !isUploadLicense ? "Add Document" : "Update Request" }}
          <i
            *ngIf="uploadedFile?.isUploading && isUploadLicense"
            class="fa fa-circle-o-notch fa-spin"
          ></i>
        </button>
      </div>
    </div>
  </div>
</section>
<input
  type="file"
  class="invisible"
  #uploadInput
  (change)="upload($event.target)"
/>
