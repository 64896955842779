import { Injectable } from '@angular/core';
import { Observable, interval } from 'rxjs';
import { map, mergeMap, startWith } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { Notification } from '../models/notification.model';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Paginated } from '../models/shipment/paginated.model';

@Injectable()
export class NotificationService {
  baseURL = environment.apiUrl + 'Notification';
  private _recentNotifications: Notification[];
  paginated: Paginated;
  isNotificationsLoading: boolean;

  constructor(private authService: AuthService, private http: HttpClient) {}

  get currentUser() {
    return this.authService.currentUser;
  }

  get recentNotifications() {
    return this._recentNotifications;
  }

  get hasUnreadNotifications() {
    return (
      this._recentNotifications?.filter(not => not.isViewed !== true)?.length >
      0
    );
  }

  get totalNotificationsCount() {
    return this.paginated?.totalItems;
  }

  set recentNotifications(notifications: Notification[]) {
    this._recentNotifications = notifications;
  }

  getEmployeeNotifications(pageIndex = 0, pageSize = 10, employeeId?: number) {
    if (this.isNotificationsLoading) {
      return;
    }

    this.isNotificationsLoading = true;

    if (!employeeId) {
      employeeId = +this.currentUser.employeeId;
    }

    if (!this.authService.isLoggedIn) {
      return;
    }

    return this.http
      .get<Paginated>(
        this.baseURL +
          `/${employeeId}?pageIndex=${pageIndex}&pageSize=${pageSize}`
      )
      .pipe(
        map(response => {
          if (response?.items?.length > 0) {
            this.getNotificationsFromResponse(response);
          }
        })
      );
  }

  // getNewNotificationsPeriodically() {
  //   return interval(300000).pipe(
  //     startWith(0),
  //     mergeMap(() => {
  //       if (!this.authService.isLoggedIn) {
  //         return;
  //       }
  //       return this.getEmployeeNotifications(
  //         null,
  //         null,
  //         +this.currentUser.employeeId
  //       );
  //     })
  //   );
  // }

  readUnreadNotification(notificationIds: number[]): Observable<any> {
    return this.http.put<Notification[]>(
      this.baseURL + `/${this.currentUser.employeeId}/View`,
      notificationIds
    );
  }

  markAllNotificationsAsRead(): Observable<any> {
    return this.http.put<Notification[]>(this.baseURL + `/View`, null);
  }

  removeCachedNotifications() {
    this.recentNotifications = null;
    this.paginated.pageIndex = 0;
  }

  private getNotificationsFromResponse(response: Paginated) {
    this.paginated = response;
    let notificationsResult = this.processResults(response?.items);
    this.pushToRecentNotifications(notificationsResult);
    return response;
  }

  pushToRecentNotifications(notificationsResult: Notification[]) {
    this.isNotificationsLoading = false;

    if (this.recentNotifications && this.recentNotifications.length > 0) {
      this.recentNotifications = [
        ...this.recentNotifications,
        ...notificationsResult
      ];
    } else {
      this.recentNotifications = notificationsResult;
    }
  }

  private processResults(notifications: Notification[]) {
    notifications.sort((a, b) => {
      return b.creationTime.valueOf() - a.creationTime.valueOf();
    });

    return notifications;
  }
}
