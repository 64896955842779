import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProductRegistrationService } from '../../../product-registration/services/product-registration.service';
import { ListFilters } from 'src/app/shared/models/list-filters.model';
import { QuoteService } from 'src/app/quote/services/quote.service';

@Component({
  selector: 'app-list-filters',
  templateUrl: './list-filters.component.html',
  styleUrls: ['./list-filters.component.scss']
})
export class ListFiltersComponent implements OnInit {
  filtersForm: UntypedFormGroup = new UntypedFormGroup({});
  filtersFormArray: UntypedFormArray = new UntypedFormArray([]);

  @Input() listFilters: ListFilters[];
  @Input() searchCriteria: any;
  @Input() isQuoteFilters: boolean = false;

  constructor(
    private fb: FormBuilder,
    private quoteService: QuoteService,
    private productRegistrationService: ProductRegistrationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ListFiltersComponent>
  ) {}

  ngOnInit(): void {
    if (this.data['searchCriteria']) {
      this.searchCriteria = this.data['searchCriteria'];
    }

    if (this.data['listFilters']) {
      this.listFilters = this.data['listFilters'];
    }

    if (this.data['isQuoteFilters']) {
      this.isQuoteFilters = this.data['isQuoteFilters'];
    }

    this.getFilters();
  }

  apply() {
    if (this.isQuoteFilters) {
      this.applyQuoteFilters();
    } else {
      this.applyProductFilters();
    }

    this.cancel();
  }

  applyQuoteFilters() {
    let selectedTypes = this.getSelectedFilters(
      this.listFilters[0]?.controlName,
      this.listFilters[0]?.listItems
    );

    let selectedModesOfTransport = this.getSelectedFilters(
      this.listFilters[1]?.controlName,
      this.listFilters[1]?.listItems
    );

    let selectedServices = this.getSelectedFilters(
      this.listFilters[2]?.controlName,
      this.listFilters[2]?.listItems
    );

    this.searchCriteria.types = selectedTypes;
    this.searchCriteria.modesOfTransport = selectedModesOfTransport;
    this.searchCriteria.services = selectedServices;

    this.quoteService.quoteSearch.emit(this.searchCriteria);
  }

  applyProductFilters() {
    let selectedStates = this.getSelectedFilters(
      this.listFilters[0]?.controlName,
      this.listFilters[0]?.listItems
    );

    let selectedProgresses = this.getSelectedFilters(
      this.listFilters[1]?.controlName,
      this.listFilters[1]?.listItems
    );

    let selectedStatuses = this.getSelectedFilters(
      this.listFilters[2]?.controlName,
      this.listFilters[2]?.listItems
    );
    let selectedCategoryClassifications = this.getSelectedFilters(
      this.listFilters[3]?.controlName,
      this.listFilters[3]?.listItems
    );

    let selectedRegulations = this.getSelectedFilters(
      this.listFilters[4]?.controlName,
      this.listFilters[4]?.listItems
    );
    let selectedSterilities = this.getSelectedFilters(
      this.listFilters[5]?.controlName,
      this.listFilters[5]?.listItems
    );
    let selectedCountryTypes = this.getSelectedFilters(
      this.listFilters[6]?.controlName,
      this.listFilters[6]?.listItems
    );

    this.searchCriteria.systemStatuses = selectedStates;
    this.searchCriteria.statuses = selectedStatuses;
    this.searchCriteria.progresses = selectedProgresses;
    this.searchCriteria.categoryClassifications = selectedCategoryClassifications;
    this.searchCriteria.regulations = selectedRegulations;
    this.searchCriteria.sterilities = selectedSterilities;
    this.searchCriteria.countryTypes = selectedCountryTypes;
    this.productRegistrationService.productRegistrationSearch.emit(
      this.searchCriteria
    );
  }

  get totalNumberOfSelectedFilters(): number {
    let totalNumberOfSelectedFilters = 0;
    this.listFilters?.forEach(element => {
      totalNumberOfSelectedFilters +=
        this.getNumberofSelectedFilters(
          element?.listItems,
          element.controlName
        ) ?? 0;
    });
    return totalNumberOfSelectedFilters;
  }

  cancel() {
    this.dialogRef.close();
  }

  clearFilters() {
    this.listFilters?.forEach(element => {
      this.clearFiltersValues(element?.controlName);
    });
  }

  setFormControlValue(
    formGroup: FormGroup,
    controlName: string,
    value: boolean
  ): void {
    formGroup.controls[controlName].patchValue(value);
  }

  getNumberofSelectedFilters(
    filterContent: string[],
    controlName: string
  ): number {
    let selectedFilterssNumber = 0;

    this.filtersFormArray?.controls?.find(element => {
      let filterFormGroup = element?.get(controlName) as FormGroup;
      if (filterFormGroup) {
        Object.entries(filterFormGroup?.controls)?.forEach(([key, value]) => {
          if (filterContent?.includes(key))
            selectedFilterssNumber += value.value;
        });
        return true;
      }
    });

    return selectedFilterssNumber;
  }

  getFilters() {
    this.intializeForm();
    this.setPreSelectedFilters();
  }

  intializeForm() {
    this.listFilters?.forEach(element => {
      this.pushFiltersToFormArray(element?.controlName, element?.listItems);
    });
    this.filtersForm = this.fb.group({
      filtersFromArray: this.filtersFormArray
    });
  }

  pushFiltersToFormArray(formControlName: string, items: string[]) {
    let newFormGroup = new UntypedFormGroup({});

    items.forEach(filterContent => {
      newFormGroup.addControl(filterContent, new UntypedFormControl(false));
    });

    this.filtersFormArray.push(
      this.fb.group({
        [formControlName]: newFormGroup
      })
    );
  }

  getFormControlValue(formGroupName, controlName: string): boolean {
    let controlValue;
    this.filtersFormArray?.controls?.find(element => {
      let filterFormGroup = element?.get(formGroupName);
      if (filterFormGroup) {
        controlValue = filterFormGroup.get(controlName)?.value;
        return true;
      }
    });

    return controlValue;
  }

  getSelectedFilters(filterType: string, filterContent: string[]): string[] {
    let selectedFilters = [];
    filterContent.forEach(data => {
      if (this.getFormControlValue(filterType, data)) {
        selectedFilters.push(data);
      }
    });
    return selectedFilters;
  }

  setPreSelectedFilters() {
    this.listFilters?.forEach(element => {
      this.setFiltersValues(element?.controlName);
    });
  }

  setFiltersValues(filterName: string) {
    let selectedFilters = this.searchCriteria[filterName];
    this.filtersFormArray?.controls?.find(element => {
      let filtersFormGroup = element?.get(filterName) as FormGroup;
      if (filtersFormGroup) {
        selectedFilters?.forEach(item => {
          this.setFormControlValue(filtersFormGroup, item, true);
        });
        return true;
      }
    });
  }

  clearFiltersValues(filterName: string) {
    this.filtersFormArray?.controls?.find(element => {
      let filtersFormGroup = element?.get(filterName) as FormGroup;
      if (filtersFormGroup) {
        Object.entries(filtersFormGroup.controls).forEach(([key]) => {
          this.setFormControlValue(filtersFormGroup, key, false);
        });
        return true;
      }
    });
  }
}
