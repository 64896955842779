import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ConfirmationDeletionComponent } from 'src/app/shared/components/confirmation-deletion/confirmation-deletion.component';
import { MatDialog } from '@angular/material/dialog';
import { ApprovalService } from 'src/app/create-approval/services/approval.service';
import { ApprovalHelperService } from '../../../approvals/services/approval-helper.service';
import { ModuleName } from 'src/app/shared/models/enums/module-name.enum';
import { ProductRegistrationHelperService } from 'src/app/create-product-registration/service/product-registration-helper.service';
import { ProductRegistrationService } from 'src/app/product-registration/services/product-registration.service';
import { AuthService } from '../../services/auth.service';
import { AlertService } from '../../services/alert.service';
import { Router } from '@angular/router';
import { ProductService } from 'src/app/product-library/services/product.service';
import { ApprovalProduct } from '../../models/approval-product.model';
import { ProductDetails } from 'src/app/create-product-registration/models/product-details.model';
import { ProductVariant } from 'src/app/create-product/models/product-variant.model';

@Component({
  selector: 'app-selected-product-in-tab',
  templateUrl: './selected-product-in-tab.component.html',
  styleUrls: ['./selected-product-in-tab.component.scss']
})
export class SelectedProductInTab implements OnInit {
  @Input() parentId: string;
  @Output() onDeleteProductChange: EventEmitter<string> = new EventEmitter<
    string
  >();
  @Input() isDisabled: boolean;
  @Input() moduleName: string;
  @Input() product: ApprovalProduct | ProductDetails;

  notesUpdate: {
    showInput: boolean;
    insertedNotes: string;
    productId: string;
  };

  constructor(
    private dialog: MatDialog,
    private approvalService: ApprovalService,
    private approvalHelperService: ApprovalHelperService,
    private productRegistrationHelperService: ProductRegistrationHelperService,
    private productRegistrationService: ProductRegistrationService,
    private authService: AuthService,
    private alertService: AlertService,
    private router: Router,
    private productService: ProductService
  ) {}

  ngOnInit(): void {
    if (this.isProductRegistration) {
      this.initializeNotesUpdate();
    }
  }

  initializeNotesUpdate() {
    this.notesUpdate = {
      showInput: false,
      insertedNotes: 'notes' in this.product ? this.product.notes : '',
      productId: this.product?.productId
    };
  }

  deleteProduct(): void {
    if (this.moduleName === ModuleName.Approval) {
      this.deleteApprovalProduct();
    } else {
      this.deleteProductRegistrationProduct();
    }
  }

  deleteApprovalProduct(): void {
    this.dialog
      .open(ConfirmationDeletionComponent, {
        hasBackdrop: true,
        width: '600px',
        maxHeight: '680px',
        panelClass: 'custom-confirmation-action-pop',
        data: {
          popUpTitle: 'Product Removal',
          popUpDescription: `You are about to delete this product from the approval request.
          Are you sure you want to continue?`,
          cancelButtonTitle: 'Keep Product',
          deleteButtonMessage: 'Delete Product Library'
        }
      })
      .afterClosed()
      .subscribe(isDelete => {
        if (isDelete) {
          this.approvalService
            .deleteProduct(this.parentId, this.productId)
            .subscribe(_ => {
              this.removeProductFromList();
              this.alertService.success(
                'Approval Product Deleted successfully'
              );
            });
        }
      });
  }

  deleteProductRegistrationProduct(): void {
    this.dialog
      .open(ConfirmationDeletionComponent, {
        hasBackdrop: true,
        width: '600px',
        maxHeight: '680px',
        panelClass: 'custom-confirmation-action-pop',
        data: {
          popUpTitle: 'Product Removal',
          popUpDescription: `You are about to delete this product from the registration request.
          Are you sure you want to continue?`,
          cancelButtonTitle: 'Keep Product',
          deleteButtonMessage: 'Remove Product'
        }
      })
      .afterClosed()
      .subscribe(isDelete => {
        if (isDelete) {
          this.productRegistrationService
            .deleteProduct(this.parentId, this.productId)
            .subscribe(_ => {
              this.alertService.success('Product Deleted successfully');
              this.productRegistrationService.productRegistrationRequestUpdated.emit();
            });
        }
      });
  }

  removeProductFromList(): void {
    this.onDeleteProductChange.emit(this.product?.productId);
  }

  toggleShowInput() {
    this.notesUpdate.showInput = !this.notesUpdate.showInput;
  }

  updateNotes() {
    let productNotes = this.notesUpdate;

    let isNotesChanged =
      'notes' in this.product
        ? productNotes.insertedNotes !== this.product.notes
        : null;

    if (
      productNotes.insertedNotes !== '' &&
      productNotes.insertedNotes !== null &&
      isNotesChanged
    ) {
      this.productRegistrationService
        .updateProductNotes(
          this.parentId,
          this.product?.productId,
          this.notesUpdate.insertedNotes
        )
        .subscribe(_ => {
          if ('notes' in this.product) {
            (this
              .product as ProductDetails).notes = this.notesUpdate.insertedNotes;
          }
          this.notesUpdate.showInput = false;
        });
    }
  }

  navigateToProductDetails(productId: string) {
    this.productService.setRedirectionModule(this.moduleName, this.parentId);

    this.router.navigate([
      '/company/' +
        this.companyId +
        '/products-library/' +
        productId +
        '/product-details'
    ]);
  }

  get isApproval(): boolean {
    return this.moduleName === ModuleName.Approval;
  }

  get isProductRegistration(): boolean {
    return this.moduleName === ModuleName.Registration;
  }

  get companyId(): string {
    return this.authService.currentUser.defaultCustomerCompany;
  }

  get partNumber(): string {
    return this.productRegistrationHelperService.getProductRegistraitonProductPartNumber(
      this.product
    );
  }

  get productName(): string {
    return this.approvalHelperService.getApprovalProductName(this.product);
  }

  get documentsCount(): string {
    return this.productRegistrationHelperService.getProductRegistrationProductDocuments(
      this.product
    );
  }

  get productDescription(): string {
    if (this.isApproval) {
      return this.approvalHelperService.getApprovalProductDescription(
        this.product
      );
    }
    return this.productRegistrationHelperService.getProductRegistrationProductDescription(
      this.product
    );
  }

  get status(): string {
    return this.approvalHelperService.getApprovalProductStatus(this.product)
      ?.name;
  }

  get productId(): string {
    return this.product?.productId;
  }

  get statusColor(): string {
    return this.approvalHelperService.getApprovalProductStatus(this.product)
      ?.colorCode;
  }

  get variants(): ProductVariant[] {
    return this.productRegistrationHelperService.getProductVariants(
      this.product as ProductDetails
    );
  }

  get canDeleteProductFromRegistration(): boolean {
    return this.authService.canDeleteProductFromRegistration;
  }
}
