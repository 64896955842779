import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HelperService } from 'src/app/shared/services/helper.service';
import { Paginated } from 'src/app/shared/models/shipment/paginated.model';

@Injectable({
  providedIn: 'root'
})
export class CountriesResolver implements Resolve<Paginated> {
  constructor(private helperService: HelperService) {}

  resolve(): Observable<Paginated> {
    return this.helperService.getCountriesPaginated().pipe(
      catchError(() => {
        return of(null);
      })
    );
  }
}
