import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import {
  AlertService,
  DialogType,
  MessageSeverity
} from 'src/app/shared/services/alert.service';
import { AppTranslationService } from 'src/app/shared/services/app-translation.service';
import { HelperService } from 'src/app/shared/services/helper.service';
import { HSCodeService } from 'src/app/admin-portal-configs/services/hsCode.service';
import { Utilities } from 'src/app/shared/services/utilities';
 import { AddEditHsCodeComponent } from '../add-edit-hs-code/add-edit-hs-code.component';
import { HSCode } from 'src/app/shared/models/shipment/hsCodes.model';
 
@Component({
  selector: 'app-hs-code-list',
  templateUrl: './hs-code-list.component.html',
  styleUrls: ['./hs-code-list.component.scss']
})
export class HsCodeListComponent implements OnInit {
  @ViewChild('indexTemplate', { static: true })
  indexTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate', { static: true })
  actionsTemplate: TemplateRef<any>;

  columns: any = [];
  loadingIndicator: boolean;
  hsCodeRows: HSCode[] = [];
  hsCodeRowsCache: HSCode[] = [];

  constructor(
    private hsCodeService: HSCodeService,
    private helperService: HelperService,
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private dialog: MatDialog,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.hsCodeService.hsCodesUpdated.subscribe(result => {
      this.updateRows(result);
    });

    this.hsCodeService.hsCodesAdded.subscribe(result => {
      this.pushAddedRows(result);
    });

    this.initializeTable();
    this.loadHSCodes();
  }

  initializeTable() {
    const gT = (key: string) => this.translationService.getTranslation(key);
    this.columns = [
      {
        prop: 'index',
        name: '#',
        width: 50,
        cellTemplate: this.indexTemplate,
        canAutoResize: false
      },
      {
        prop: 'code',
        name: gT('config.management.HSCodes.HSCode'),
        width: 100
      },
      {
        prop: 'options',
        name: gT('config.management.HSCodes.Manage'),
        width: 270,
        cellTemplate: this.actionsTemplate,
        resizeable: false,
        canAutoResize: false,
        sortable: false,
        draggable: false
      }
    ];
  }

  updateRows(hsCode: HSCode) {
    this.hsCodeRows = this.updateRow(hsCode, this.hsCodeRows);
    this.hsCodeRowsCache = this.updateRow(hsCode, this.hsCodeRowsCache);
  }

  updateRow(newHSCode: HSCode, hsCodes: HSCode[]) {
    return hsCodes.map(object => {
      if (object.id === newHSCode.id) {
        return { ...object, code: newHSCode.code };
      }
      return object;
    });
  }

  pushAddedRows(hsCodes: HSCode[]) {
    this.hsCodeRows.unshift(...hsCodes);
    this.hsCodeRows = this.initiateIndex(this.hsCodeRows);
    this.hsCodeRows = [...this.hsCodeRows];
    this.hsCodeRowsCache.unshift(...hsCodes);
    this.hsCodeRowsCache = this.initiateIndex(this.hsCodeRowsCache);
  }

  initiateIndex(hsCodes: HSCode[]) {
    hsCodes.forEach((hsCode, index) => {
      (hsCode as any).index = index + 1;
    });
    return hsCodes;
  }

  loadHSCodes() {
    this.route.data.subscribe(data => {
      let hsCodes = data['HSCodes'];
      hsCodes = this.initiateIndex(hsCodes);
      this.hsCodeRowsCache = [...hsCodes];
      this.hsCodeRows = hsCodes;
    });
  }

  onSearchHSCode(value: string) {
    let trimValue = value.replace(/\s/g, '');
    let valueConverted = parseInt(trimValue);
    if (
      value &&
      !isNaN(valueConverted) &&
      trimValue?.length >= 4 &&
      trimValue.length <= 10
    ) {
      this.fetchHSCodes(trimValue);
    } else {
      this.hsCodeRows = [...this.hsCodeRowsCache];
    }
  }

  fetchHSCodes(code: string) {
    this.helperService.getHSCodes(code).subscribe(
      results => {
        this.changeLoadingStatus(false, '');
        let hsCodes = results;
        hsCodes = this.initiateIndex(hsCodes);
        this.hsCodeRows = hsCodes;
      },
      error => {
        this.changeLoadingStatus(false, '');
        this.showStickyMessage(
          'Load Error',
          'Unable to retrieve HS Codes from the server.',
          error
        );
      }
    );
  }

  addOrEditHSCode(row?: HSCode) {
    const dialogRef = this.dialog.open(AddEditHsCodeComponent, {
      hasBackdrop: true,
      width: '450px',
      data: { hsCode: row }
    });
  }

  deleteHSCode(hSCode: HSCode) {
    this.alertService.showDialog(
      'Are you sure you want to delete the "' + hSCode.code + '" HS Code?',
      DialogType.confirm,
      () => this.deleteHSCodeHelper(hSCode)
    );
  }

  deleteHSCodeHelper(hsCode: HSCode) {
    this.changeLoadingStatus(true, 'Deleting...');
    this.hsCodeService.deleteHSCode(hsCode.id.toString()).subscribe(
      results => {
        this.changeLoadingStatus(false, '');
        let deletedIndex = this.hsCodeRowsCache.findIndex(
          item => item.id == hsCode.id
        );
        this.hsCodeRowsCache.splice(deletedIndex, 1);
        this.hsCodeRowsCache = this.initiateIndex(this.hsCodeRowsCache);

        this.hsCodeRows = this.hsCodeRows.filter(item => item !== hsCode);
        this.hsCodeRows = this.initiateIndex(this.hsCodeRows); 
      },
      error => {
        this.changeLoadingStatus(false, '');
        this.showStickyMessage(
          'Delete Error',
          'An error occured whilst deleting the HS Code.',
          error
        );
      }
    );
  }

  changeLoadingStatus(loadingStatus: boolean, loadingMessage: string) {
    if (loadingStatus) {
      this.alertService.startLoadingMessage(loadingMessage);
    } else {
      this.alertService.stopLoadingMessage();
    }
    this.loadingIndicator = loadingStatus;
  }

  showStickyMessage(summary: string, details: string, error: any) {
    this.alertService.showStickyMessage(
      summary,
      details + `\r\nError: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error,
      error
    );
  }
}
