<form [formGroup]="hsCodeForm" id="currencyForm" (ngSubmit)="save()">
  <div class="modal-header">
    <h4 class="modal-title float-left">
      <i class="fa fa-shield"></i>
      {{
        isEditingMode
          ? ("config.management.HSCodes.Edit" | translate: isEditingMode)
          : ("config.management.HSCodes.New" | translate)
      }}
    </h4>
    <button
      type="button"
      class="close float-right"
      title="Close"
      (click)="dialogRef.close()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div *ngIf="isDuplicatedHSCode" class="error-message">
    <app-error-badge
      [thinBar]="true"
      message="HS Code already Exists."
      class="warning-badge"
    ></app-error-badge>
  </div>

  <div class="form-group">
    <label for="hsCode">{{
      "config.management.HSCodes.HSCode" | translate
    }}</label>

    <input
      formControlName="code"
      [ngClass]="{
        'is-invalid':
          hsCodeForm.get('code').errors && hsCodeForm.get('code').touched
      }"
      type="text"
      class="form-control"
      id="code"
      placeholder="{{
        'config.management.HSCodes.HSCodePlaceHolder' | translate
      }}"
    />
    <small
      *ngIf="
        hsCodeForm.get('code').touched &&
        hsCodeForm.get('code').hasError('required')
      "
      class="form-text text-danger"
      >{{ "Please enter HS Code." }}</small
    >
    <small
      *ngIf="
        hsCodeForm.get('code').touched &&
        (hsCodeForm.get('code').hasError('minlength') ||
          hsCodeForm.get('code').hasError('maxlength') ||
          hsCodeForm.get('code').hasError('oddNumber') ||
          hsCodeForm.get('code').hasError('pattern'))
      "
      class="form-text text-danger"
      >Invalid HS Code. HS Codes consist of 4, 6, 8 or 10 digits.</small
    >
  </div>

  <div class="col-sm-12 form-group">
    <div ngPreserveWhitespaces class="float-right">
      <button type="button" class="btn btn-danger" (click)="cancel()">
        <i class="fa fa-times"></i>
        {{ "config.management.HSCodes.Editor.Cancel" | translate }}
      </button>
      <button
        type="submit"
        class="btn btn-primary"
        [disabled]="isSaving || !hsCodeForm.valid"
      >
        <i *ngIf="!isSaving" class="fa fa-save"></i>
        <i *ngIf="isSaving" class="fa fa-circle-o-notch fa-spin"></i>
        {{
          isSaving
            ? ("config.management.HSCodes.Editor.Saving" | translate)
            : ("config.management.HSCodes.Editor.Save" | translate)
        }}
      </button>
    </div>
  </div>
</form>
