import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CountryMaster } from 'src/app/shared/models/country.model';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AppTranslationService } from 'src/app/shared/services/app-translation.service';
import { HelperService } from 'src/app/shared/services/helper.service';
import { PortDto } from '../../../models/portDto.model';
import { PortService } from '../../../services/port.service';
import { PortListComponent } from '../port-list/port-list.component';

@Component({
  selector: 'app-add-or-edit-port',
  templateUrl: './add-or-edit-port.component.html',
  styleUrls: ['./add-or-edit-port.component.scss']
})
export class AddOrEditPortComponent implements OnInit {
  port: PortDto;
  portForm: UntypedFormGroup;
  isForEditing: boolean;
  isSaving: boolean = false;
  countries: CountryMaster[];
  countryName: string;
  cityID: number;
  country: CountryMaster;
  isDuplicatedPort: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private portService: PortService,
    private translationService: AppTranslationService,
    private helperService: HelperService,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<PortListComponent>
  ) {
    this.port = data['port'];
    this.countries = data['countries'];
    this.isForEditing = data['isForEditing'];
  }

  ngOnInit(): void {
    this.port = this.port || ({} as PortDto);

    this.portForm = this.fb.group({
      name: [this.port.name, [Validators.required]],
      code: [this.port.code, [Validators.required]],
      isAirPort: [this.port.isAirPort],
      isDryPort: [this.port.isDryPort],
      isLandPort: [this.port.isLandPort],
      isOceanPort: [this.port.isOceanPort],
      countryName: [this.port.countryName, [Validators.required]],
      cityID: [this.port.cityId, [Validators.required]]
    });

    this.fillModalForm(this.port);
  }

  save() {
    const formVal = this.portForm.value;

    if (!this.portForm.valid) {
      this.alertService.error('Invalid Form!');
      return;
    }

    if (this.isForEditing) {
      this.updateRequest(formVal);
    } else {
      this.addNewRequest(formVal);
    }
  }

  onChangeCountry(event) {
    var name = event.target.value;
    this.country = this.countries.find(e => e.name == name);
    this.countryName = this.country.name;
  }

  onChangeCity(event) {
    this.cityID = event.target.value;
  }

  updateRequest(port: PortDto) {
    this.portService.updatePort(this.port.id, port).subscribe(
      data => {
        this.portService.portUpdatedEmitter.emit({
          updatedPort: data
        });
        return true;
      },
      error => {
        this.handleError(error);
      }
    );
    return false;
  }

  addNewRequest(port: PortDto) {
    this.portService.addNewPort(port).subscribe(
      data => {
        this.portService.portAddedEmitter.emit({
          addedPort: data
        });
        return true;
      },
      error => {
        this.handleError(error);
      }
    );
    return false;
  }

  cancel() {
    this.resetForm();
    this.dialogRef.close();
  }

  fillModalForm(row: PortDto) {
    this.country = this.countries.find(e => e.id == row.countryId);
    this.cityID = this.port.cityId;
  }

  resetForm() {
    this.portForm.reset();
  }

  handleError(error) {
    if (error === 409) {
      this.isDuplicatedPort = true;
    } else this.alertService.error('Error!');
    this.isSaving = false;
    return false;
  }
}
