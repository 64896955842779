<mat-sidenav-container>
  <mat-sidenav [(opened)]="openSection"
               position="end"
               mode="side"
               #commentsSidenav>
    <div class="comments-drawer">
      <div class="header">
        <svg-icon [name]="'comments_section_icon_preview'"
                  class="comments-preview-icon"></svg-icon>
        <span class="comments-title">
          COMMENTS
        </span>

        <button class="float-right refresh-button"
                (click)="onCommentsRefresh()">
          <svg-icon class="refresh-icon" [name]="'refresh-button'"></svg-icon>
        </button>
      </div>
      <div class="line-seperator"></div>
      <div id="comments-feed">
        <div class="comments-section">
          <span *ngIf="!noCommentsFeed; else noCommentsPage">
            <span *ngFor="let groupDate of dateGroups">
              <span class="date-of-comments">
                {{ groupDate.dateCategoryName }}
              </span>
              <span *ngFor="let item of groupDate.comments"
                    [class]="
                  currentUserId == item?.actionActor?.actorId
                    ? 'd-flex justify-content-end mb-3'
                    : 'd-flex justify-content-start mb-3'
                ">
                <img class="user-picture"
                     [hidden]="
                    currentUserId == item?.actionActor?.actorId ||
                    !item?.actionActor?.actorProfilePictureUrl
                  "
                     [src]="item?.actionActor?.actorProfilePictureUrl" />
                <div class="default-user-picture"
                     [hidden]="
                    currentUserId == item?.actionActor?.actorId ||
                    item?.actionActor?.actorProfilePictureUrl
                  ">
                  {{ item?.actionActor?.actorName | userNameInitials }}
                </div>
                <div>
                  <div class="comment"
                       [class]="
                      item.isPendingAction == true || item.isFulFill == true
                        ? 'pending-comment'
                        : currentUserId == item?.actionActor?.actorId
                        ? 'my-comment'
                        : 'others-comments'
                    "
                       [id]="item?.id">
                    <span class="user-name"
                          [hidden]="currentUserId == item?.actionActor?.actorId">{{ item?.actionActor?.actorName }}</span>
                    <span [class]="
                        item.isPendingAction == true || item.isFulFill == true
                          ? 'pending-message-content'
                          : currentUserId == item?.actionActor?.actorId
                          ? 'my-message-content'
                          : 'others-message-content'
                      ">
                      {{ item.content }}

                      <span *ngIf="
                          item.isPendingAction == true &&
                          item.isFulFill == false
                        ">
                        <div class="pending-action-section">
                          <div class="d-inline-flex">
                            <svg-icon name="small-alert-Mark"
                                      class="alert-mark"></svg-icon>
                            <div class="pending-action-label">
                              <span>
                                Pending Action
                              </span>
                            </div>
                            <span class="mark-fulfilled float-right enabled"
                                  *ngIf="isAccountManager"
                                  (click)="markCommentAsFulfilled(item)">
                              Mark as fulfilled
                            </span>
                          </div>
                        </div>
                      </span>

                      <span *ngIf="
                          item.isFulFill == true
                        ">
                        <div class="pending-action-section">
                          <app-comment-pending-action-fulfill-information [fulfillActor]="item?.fulfillActor"
                                                                          [fulfillDate]="item?.fulFillDate"></app-comment-pending-action-fulfill-information>
                          <span class="mark-fulfilled float-right disabled"
                                *ngIf="isAccountManager">
                            Mark as fulfilled
                          </span>
                        </div>
                      </span>
                    </span>
                  </div>

                  <span [class]="
                      currentUserId == item?.actionActor?.actorId
                        ? 'my-message-sent-at'
                        : 'others-message-sent-at'
                    ">
                    <span class="try-again-msg"
                          [hidden]="
                        currentUserId != item?.actionActor?.actorId ||
                        !item.showErrorMessage
                      ">
                      <span class="error-text">
                        An error has occurred.
                      </span>
                      <span class="try-again-btn" (click)="tryAgain(item)">
                        Try Again.
                      </span>
                    </span>
                    <span class="timestamp">
                      {{
                      item.createdAt | fromutc | date: "shortTime"
                      }}
                    </span>
                  </span>
                </div>

                <img class="my-user-picture"
                     [hidden]="
                    currentUserId != item?.actionActor?.actorId ||
                    !item?.actionActor?.actorProfilePictureUrl
                  "
                     [src]="item?.actionActor?.actorProfilePictureUrl" />
                <div class="default-user-picture"
                     [hidden]="
                    currentUserId != item?.actionActor?.actorId ||
                    item?.actionActor?.actorProfilePictureUrl
                  ">
                  {{ item?.actionActor?.actorName | userNameInitials }}
                </div>
              </span>
            </span>
          </span>
        </div>
      </div>

      <ng-template #noCommentsPage>
        <div class="no-comments-container">
          <svg-icon [name]="'no-comments-icon'"
                    class="no-comments-icon"></svg-icon>
          <span class="no-comments-message">
            There are no comments made for this request yet
          </span>
        </div>
      </ng-template>
    </div>
    <form id="commentForm"
          class="comment-form"
          [formGroup]="commentForm"
          (ngSubmit)="save()">
      <div class="add-comment-container">
        <input id="comment"
               formControlName="comment"
               placeholder="Start typing ..."
               class="form-control comment-text-box"
               autocomplete="off"
               maxlength="250" />
        <button type="submit" class="container-box">
          <svg-icon [name]="'comment-send-icon'"
                    class="submit-comment-btn"></svg-icon>
        </button>
      </div>

      <mat-checkbox *ngIf="isAccountManager"
                    formControlName="isPendingAction"
                    class="pending-action-request">
        Pending Action Request
      </mat-checkbox>
    </form>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="drawer-control">
      <span *ngIf="!openSection; else closeDrawer">
        <div class="drawer-expand-btn" (click)="openSection = !openSection">
          <svg-icon [name]="'expand-section-button'"
                    class="expand-pointer-background"
                    (click)="openSection = !openSection"></svg-icon>
          <svg-icon [name]="'expand-drawer'"
                    class="expand-pointer"
                    (click)="openSection = !openSection"></svg-icon>
          <svg-icon [name]="'comments-section-icon-default'"
                    class="comments-section-default"></svg-icon>
          <span class="product-comments">
            Product Comments
          </span>
        </div>
      </span>
      <ng-template #closeDrawer>
        <svg-icon class="drawer-collapse-btn"
                  [name]="'collapse-section-button'"
                  (click)="openSection = !openSection"></svg-icon>
      </ng-template>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
