import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PortDto } from '../../admin-portal-configs/models/portDto.model';
import { PortService } from '../../admin-portal-configs/services/port.service';

@Injectable({
  providedIn: 'root'
})
export class PortsResolver implements Resolve<PortDto[]> {
  constructor(private portService: PortService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<PortDto[]> {
    return this.portService.searchPorts('', '', null, 0, 30).pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}
