import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UploadDocumentComponent } from 'src/app/shared/components/upload-document/upload-document/upload-document.component';
import { DocumentParentType } from 'src/app/shared/models/enums/document-parent-type.model';
import { UploadDocumentPopUpPurpose } from 'src/app/shared/models/enums/upload-document-pop-up-purpose.enum';
import { DocumentService } from 'src/app/shared/services/document.service';
import { Document } from 'src/app/shared/models/document.model';
import { HttpEventType } from '@angular/common/http';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ShipmentDocument } from 'src/app/shared/models/shipment/shipment-document.model';
import { CompanyDocument } from 'src/app/shared/models/company-document';
import { ModuleName } from 'src/app/shared/models/enums/module-name.enum';
import { FreePLUser } from 'src/app/shared/models/freepl-user.model';
import { CompanyService } from 'src/app/company/services/company.service';

@Component({
  selector: 'app-profile-documents-list',
  templateUrl: './profile-documents-list.component.html',
  styleUrls: ['./profile-documents-list.component.scss']
})
export class ProfileDocumentsListComponent implements OnInit {
  //[TODO]: rename this component to 'DocumentsListComponent' after removing old customer module
  documents: Document[];
  documentToReplaceId: number;
  isDeleting: boolean = false;
  insertedNotes: string;
  isShowInput: boolean = false;
  companyDocuments: CompanyDocument[];
  documentParentType = DocumentParentType;
  moduleName = ModuleName;
  isUploadingFile: boolean;
  uploadedFileType: string;

  constructor(
    private dialog: MatDialog,
    private documentService: DocumentService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private companyService: CompanyService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(data => {
      this.documents = data['documents'];
      this.companyDocuments = this.documents?.map(document => {
        return this.mapDocument(document);
      });
    });

    this.documentService.fileUploadingEmitter.subscribe(res => {
      this.adduploadingDocument(
        res.data,
        res.uploadedFile,
        res.isError,
        res.type
      );
      this.uploadedFileType = res.uploadedFile.typeName;
      this.isUploadingFile = !res.data;
    });

    this.documentService.documentDeleted.subscribe(() => {
      this.getDocuments();
    });
  }

  getDocuments() {
    this.companyService.getDocuments(+this.companyId).subscribe(res => {
      this.documents = res;
      this.companyDocuments = this.documents?.map(document => {
        return this.mapDocument(document);
      });
    });
  }

  openUploadDocumentPopup() {
    this.dialog.open(UploadDocumentComponent, {
      hasBackdrop: true,
      width: '540px',
      maxHeight: '750px',
      data: {
        popUpPurpose: UploadDocumentPopUpPurpose.AddNewDocument,
        companyId: this.companyId,
        documents: this.selectedDocuments,
        parentType: DocumentParentType.CompanyProfile
      }
    });
  }

  get selectedDocuments(): ShipmentDocument[] {
    let selectedDocuments = this.companyDocuments?.map(x => {
      return {
        documentTypeId: x.documentTypeId,
        documentTypeName: x?.documentTypeName,
        path: x.path,
        fileName: x.fileName,
        notes: x.description,
        documentSqlId: x.id,
        isDeleted: x.isDeleted,
        createdAt: x.uploadDateTime
      } as ShipmentDocument;
    });
    return selectedDocuments;
  }

  get companyId(): string {
    return this.router.url.split('/')[2];
  }

  adduploadingDocument(
    uploadedDocument: CompanyDocument,
    uploadingFile,
    isError: boolean,
    type: any
  ) {
    if (!this?.companyDocuments) {
      this.companyDocuments = [];
    }
    const indexOfUploadingFile = this?.companyDocuments.findIndex(
      x => x.documentTypeName === uploadingFile.typeName
    );
    if (uploadedDocument && type === HttpEventType['Response']) {
      this.companyDocuments[indexOfUploadingFile] = uploadedDocument;

      if (isError) {
        this.alertService.error('Document upload was unsuccessfull !');
      } else {
        var isReplaced = uploadedDocument.oldVersions?.length &&
          !uploadedDocument.oldVersions[
            uploadedDocument.oldVersions.length - 1
          ].isDeleted;

        this.alertService.success(
          isReplaced
            ? 'Document successfully replaced.'
            : 'Document successfully uploaded !'
        );
      }
    }

    if (type === HttpEventType['UploadProgress'] && isError != true) {
      const newDocumentStructure = {
        documentTypeName: uploadingFile.typeName,
        fileName: uploadingFile.fileName,
        documentTypeId: null,
        path: null,
        notes: uploadingFile.note,
        documentSqlId: null,
        id: null,
        parentType: '',
        parentId: 0,
        description: '',
        shipmentRfqId: 0,
        documentType: undefined,
        isDeleted: false,
        uploadDateTime: undefined,
        validToDate: undefined,
        hasExpirationDate: false
      };
      if (indexOfUploadingFile === -1) {
        this.companyDocuments.push(newDocumentStructure);
      }

      if (indexOfUploadingFile >= 0) {
        this.companyDocuments[indexOfUploadingFile] = newDocumentStructure;
      }
    }
    this.sortTheDocuments();
  }
  private sortTheDocuments() {
    this.companyDocuments = this?.companyDocuments.sort((a, b) =>
      a.documentTypeName < b.documentTypeName ? -1 : 1
    );
  }
  private mapDocument(document: Document): CompanyDocument {
    let companyDocument: CompanyDocument = {
      notes: document.description,
      id: document.id,
      documentTypeId: document.documentTypeId,
      parentType: document.parentType,
      parentId: document.parentId,
      description: document.description,
      shipmentRfqId: 0,
      documentType: document.documentType,
      path: document.path,
      fileName: document.fileName,
      isDeleted: document.isDeleted,
      uploadDateTime: document.uploadDateTime,
      validToDate: document.validToDate,
      hasExpirationDate: document.hasExpirationDate,
      expirationDate: document.validToDate,
      documentTypeName: document.documentType.name,
      createdAt: document.uploadDateTime,
      oldVersions:
        document?.olderVersions?.length > 0
          ? document?.olderVersions?.map(x => {
              return this.mapDocument(x);
            })
          : null,
      documentSqlId: document.id,
      uploadedBy: {
        id: document?.uploadedBy?.userId,
        employeeId: document?.uploadedBy?.id?.toString(),
        fullName:
          document?.uploadedBy?.firstName +
          ' ' +
          document?.uploadedBy?.lastName,
        companyName: document?.uploadedBy?.company?.name
      } as FreePLUser,
      deletedBy: {
        id: document?.deletedBy?.userId,
        employeeId: document?.deletedBy?.id?.toString(),
        fullName:
          document?.deletedBy?.firstName + ' ' + document?.deletedBy?.lastName,
        companyName: document?.deletedBy?.company?.name
      } as FreePLUser,
      deletionReason: document?.deletionReason,
      deletedAt: document?.deletedAt
    } as CompanyDocument;

    return companyDocument;
  }
}
