
import { Component } from '@angular/core';



@Component({
  selector: 'app-banner-demo',
  templateUrl: './banner-demo.component.html'
})
export class BannerDemoComponent {

}
