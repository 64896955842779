import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { concat, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
  tap
} from 'rxjs/operators';
import { DistrictService } from 'src/app/admin-portal-configs/services/district.service';
import { ServiceTypes } from 'src/app/shared/models/shipment/service-types.enum';
import { District } from '../../models/district.models';
import { PortService } from 'src/app/admin-portal-configs/services/port.service';
import { TransportTypeEnum } from '../../models/enums/transport-type.enum';
import { PortDto } from 'src/app/admin-portal-configs/models/portDto.model';
import { CompanyService } from 'src/app/company/services/company.service';
import { RouteDetails } from 'src/app/supplier/models/route-details.model';

@Component({
  selector: 'app-trucking-route-details-section',
  templateUrl: './trucking-route-details-section.component.html',
  styleUrls: ['./trucking-route-details-section.component.scss']
})
export class TruckingRouteDetailsSectionComponent implements OnInit {
  @Input() routeDetailsForm: FormGroup;
  @Input() routeDetails: RouteDetails;
  routeDirectionLookup: string[] = [];
  egyptId: number;

  @Input() isSameRoute: boolean = false;

  @Input() isForCustomerCompany: boolean = false;

  districtsPickUpFrom$ = new Subject<string>();
  districtsObservablePickUpFrom: Observable<any> = new Observable<any>();
  isDistrictsLoading: boolean = false;

  districtsDeliverTo$ = new Subject<string>();
  districtsObservableDeliverTo: Observable<any> = new Observable<any>();

  lastDistrictsPickUpFrom: District[];
  lastDistrictsDeliverTo: District[];

  ports$ = new Subject<string>();
  portsObservable: Observable<any>;
  isPortsLoading = false;
  lastPorts: PortDto[] = [];

  constructor(
    private router: Router,
    private districtService: DistrictService,
    private portService: PortService,
    private companyService: CompanyService
  ) {}

  ngOnInit() {
    this.getStaticLookups();
    this.getEgyptId();

    if (!this.isDomesticRoute) {
      this.initPortValue();
    }

    if (this.isForCustomerCompany) {
      this.setRouteDetails();
    }
  }

  setRouteDetails() {
    this.lastPorts = this.isDoorToPort
      ? [this.routeDetails.deliverTo]
      : [this.routeDetails.pickupFrom];

    let portFormControl = this.isDoorToPort ? 'deliverTo' : 'pickupFrom';
    this.routeDetailsForm
      .get(portFormControl)
      .patchValue(this.lastPorts[0]?.id);

    let districtsFormControl = this.isDoorToPort ? 'pickupFrom' : 'deliverTo';
    let district: any = this.isDoorToPort
      ? this.routeDetails?.pickupFrom
      : this.routeDetails?.deliverTo;

    this.routeDetailsForm.get(districtsFormControl).patchValue(district?.id);

    if (this.isDoorToPort) {
      this.lastDistrictsPickUpFrom = [district];
    } else {
      this.lastDistrictsDeliverTo = [district];
    }
  }

  isInvalid(formControlName: string): boolean {
    return (
      this.routeDetailsForm?.get(formControlName).errors &&
      this.routeDetailsForm?.get(formControlName)?.touched
    );
  }

  onChangeRouteDirection() {
    this.resetLocation('deliverTo');
    this.resetLocation('pickupFrom');
  }

  resetLocation(formGroup: string) {
    let locationForm = this.routeDetailsForm?.get(formGroup) as FormGroup;
    locationForm?.get('id')?.setValue(null);
    locationForm?.get('location')?.setValue(null);
    locationForm?.get('cityId')?.setValue(null);
    locationForm?.get('city')?.setValue(null);
    locationForm?.get('country')?.setValue(null);
    locationForm?.get('countryId')?.setValue(null);
  }

  loadDistrictsPickUpFrom$() {
    this.districtsObservablePickUpFrom = concat(
      of([]), // default items
      this.districtsPickUpFrom$.pipe(
        filter(res => {
          return res !== null && res.length >= 3;
        }),
        distinctUntilChanged(),
        debounceTime(800),
        tap(_ => (this.isDistrictsLoading = true)),
        switchMap(textOfSearch => {
          return this.districtService
            .getByNameAndCountryId(textOfSearch, this.egyptId)
            .pipe(
              catchError(() => of([])),
              tap((value: District[]) => {
                this.isDistrictsLoading = false;
                this.lastDistrictsPickUpFrom = value;
                if (
                  !this.lastDistrictsPickUpFrom ||
                  this.lastDistrictsPickUpFrom?.length <= 0
                ) {
                  let formControlName = 'pickupFrom';
                  this.clearFormControl(formControlName);
                }
              })
            );
        })
      )
    );
  }

  clearFormControl(controlName: string) {
    let control = this.routeDetailsForm?.get(controlName);
    control.patchValue(null);
  }

  getStaticLookups() {
    this.routeDirectionLookup = [
      ServiceTypes.DoorToPort,
      ServiceTypes.PortToDoor
    ];
  }

  loadPorts$(): void {
    this.portsObservable = concat(
      of([]), // default items
      this.ports$.pipe(
        filter(res => {
          return res !== null && res.length >= 3;
        }),
        distinctUntilChanged(),
        debounceTime(800),
        tap(_ => (this.isPortsLoading = true)),
        switchMap(textOfSearch => {
          return this.portService
            .filterPorts(textOfSearch, TransportTypeEnum.Trucking, this.egyptId)
            .pipe(
              catchError(() => of([])),
              tap((value: PortDto[]) => {
                this.isPortsLoading = false;
                this.lastPorts = value;
                if (!this.lastPorts || this.lastPorts?.length <= 0) {
                  let formControlName = this.isDoorToPort
                    ? 'deliverTo'
                    : 'pickupFrom';
                  this.clearFormControl(formControlName);
                }
              })
            );
        })
      )
    );
  }

  loadDistrictsDeliverTo$() {
    this.districtsObservableDeliverTo = concat(
      of([]), // default items
      this.districtsDeliverTo$.pipe(
        filter(res => {
          return res !== null && res.length >= 3;
        }),
        distinctUntilChanged(),
        debounceTime(800),
        tap(_ => (this.isDistrictsLoading = true)),
        switchMap(textOfSearch => {
          return this.districtService
            .getByNameAndCountryId(textOfSearch, this.egyptId)
            .pipe(
              catchError(() => of([])),
              tap((value: District[]) => {
                this.isDistrictsLoading = false;
                this.lastDistrictsDeliverTo = value;
                if (
                  !this.lastDistrictsDeliverTo ||
                  this.lastDistrictsDeliverTo?.length <= 0
                ) {
                  let formControlName = 'routeDetails.deliverTo';
                  this.clearFormControl(formControlName);
                }
              })
            );
        })
      )
    );
  }

  getEgyptId() {
    this.companyService.getEgyptId().subscribe(data => {
      this.egyptId = data;
      this.initDistrictValue();
      this.loadDistrictsPickUpFrom$();
      this.loadDistrictsDeliverTo$();
      this.loadPorts$();
    });
  }

  initDistrictValue() {
    if (this.isForEditing) {
      if (!this.isDomesticRoute) {
        let districtName = !this.isDoorToPort
          ? this.routeDetails?.deliverTo?.name
          : this.routeDetails?.pickupFrom?.name;
        this.getDistrictAbstract(
          districtName,
          this.isDoorToPort ? 'pickupFrom' : 'deliverTo'
        );
      } else {
        let deliverToDistrict = this.routeDetails?.deliverTo?.name;
        let pickUpDistrict = this.routeDetails?.pickupFrom?.name;
        this.getDistrictAbstract(deliverToDistrict, 'deliverTo');
        this.getDistrictAbstract(pickUpDistrict, 'pickupFrom');
      }
    }
  }

  getDistrictAbstract(districtName: string, formControlName: string) {
    this.districtService
      .getByNameAndCountryId(districtName, this.egyptId)
      .subscribe(($res: District[]) => {
        if (!$res || $res?.length <= 0) {
          return;
        }
        if (formControlName == 'pickupFrom') {
          this.lastDistrictsPickUpFrom = $res;
        } else {
          this.lastDistrictsDeliverTo = $res;
        }
        this.routeDetailsForm?.get(formControlName)?.setValue($res[0]);
      });
  }

  initPortValue() {
    if (this.isForEditing) {
      let portName = this.isDoorToPort
        ? this.routeDetails?.deliverTo?.name
        : this.routeDetails?.pickupFrom?.name;
      this.getPort(portName);
    }
  }

  getPort(portName: string) {
    this.portService
      .filterPorts(portName, TransportTypeEnum.Trucking, this.egyptId)
      .subscribe(($res: PortDto[]) => {
        this.lastPorts = $res;
        if (!$res || $res?.length <= 0) {
          return;
        }
        if (this.isDoorToPort) {
          this.routeDetailsForm?.get('deliverTo')?.setValue($res[0]);
        } else {
          this.routeDetailsForm?.get('pickupFrom')?.setValue($res[0]);
        }
      });
  }

  get isDomesticRoute(): boolean {
    return this.router.url.includes('domestic-trucking-pricelist');
  }

  get isForEditing(): boolean {
    return this.router.url.includes('edit');
  }

  get isDoorToPort(): boolean {
    return (
      this.routeDetailsForm?.get('routeDirection')?.value ==
      ServiceTypes.DoorToPort
    );
  }
}
