<br />
<div class="supplier-container">
  <div class="d-flex justify-content-between">
    <app-page-header
      [title]="'mainMenu.Suppliers' | translate"
    ></app-page-header>
    <button class="create-supplier" [routerLink]="['/suppliers/add']">
      <span class="plus">+</span>
      <span class="create-supplier-btn ">New Supplier</span>
    </button>
  </div>

  <app-g-search-bar [targerModule]="entityType.Supplier"> </app-g-search-bar>

  <div class="mt-3">
    <ngx-datatable
      class="material colored-header sm table table-striped table-hover"
      [rows]="allSuppliers"
      [rowHeight]="40"
      [headerHeight]="40"
      [footerHeight]="35"
      [columns]="columns"
      [scrollbarV]="true"
      [columnMode]="'force'"
    >
    </ngx-datatable>

    <ng-template #nameTemplate let-value="value">
      <span class="supplier-name">{{ value }}</span>
    </ng-template>

    <ng-template #headquatersTemplate let-value="value">
      <span class="headquaters">{{ getHeadquarters(value) }}</span>
    </ng-template>

    <ng-template #statusTemplate let-value="value">
      <span
        class="status"
        [ngClass]="{
          'active-status': value === 'Active',
          'inactive-status': value === 'Inactive'
        }"
        >{{ value }}</span
      >
    </ng-template>

    <ng-template #actionsTemplate let-row="row" let-value="value" let-i="id">
      <button
        class="btn btn-link btn-sm"
        [routerLink]="['./' + row.id + '/details']"
      >
        <svg-icon name="view-supplier"></svg-icon>
      </button>
    </ng-template>
  </div>
</div>
