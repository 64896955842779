import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CityLocation } from 'src/app/admin-portal-configs/models/city-location.model';
import { CountryMaster } from 'src/app/shared/models/country.model';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';
import { AppTranslationService } from 'src/app/shared/services/app-translation.service';
 import { HelperService } from 'src/app/shared/services/helper.service';
import { Utilities } from 'src/app/shared/services/utilities';
import { CityService } from '../../../services/city.service';

@Component({
  selector: 'app-add-or-edit-cities',
  templateUrl: './add-or-edit-cities.component.html',
  styleUrls: ['./add-or-edit-cities.component.scss']
})
export class AddOrEditCitiesComponent implements OnInit {
  cityForm: UntypedFormGroup;
  cityWithLocation: CityLocation;
  countries: CountryMaster[];
  isSaving: boolean = false;
  duplicateCity: boolean = false;
  isNewCity: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private translationService: AppTranslationService,
    private alertService: AlertService,
    private cityService: CityService,
    private helperService: HelperService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.cityWithLocation = data['city'];
    });

    this.getCountries();
    this.initForm();
  }

  getCountries() {
    this.helperService.getCountries().subscribe(
      data => {
        this.countries = data;
      },
      error => {
        this.alertService.showStickyMessage(
          'Error',
          `An error occured whilst retreiving a list of countries.\r\nError: "${Utilities.getHttpResponseMessages(
            error
          )}"`,
          MessageSeverity.error,
          error
        );
      }
    );
  }

  initForm() {
    this.isNewCity = this.cityWithLocation == null;
    this.cityWithLocation = this.cityWithLocation || ({} as CityLocation);

    this.cityForm = this.fb.group({
      city: [this.cityWithLocation.city, [Validators.required]],
      countryId: [this.cityWithLocation.countryId, [Validators.required]],
      cityAscii: [this.cityWithLocation.cityAscii, [Validators.required]],
      lat: [this.cityWithLocation.lat, [Validators.required]],
      lng: [this.cityWithLocation.lng, [Validators.required]]
    });
  }

  save() {
    const formVal = this.cityForm.value;

    if (!this.cityForm.valid) {
      this.alertService.error('Invalid Form!');
      return;
    }

    formVal.country = this.getCountryName(formVal.countryId);

    if (this.isNewCity) {
      this.addNewRequest(formVal);
    } else {
      this.updateRequest(formVal);
    }
  }

  updateRequest(formVal: CityLocation) {
    this.cityService.updateCity(this.cityWithLocation.id, formVal).subscribe(
      data => {
        this.saveSuccessHelper(data);
      },
      error => {
        this.saveFailedHelper(error);
      }
    );
  }

  addNewRequest(formVal: CityLocation) {
    this.cityService.addNewCity(formVal).subscribe(
      data => {
        this.saveSuccessHelper(data);
      },
      error => {
        this.saveFailedHelper(error);
      }
    );
  }

  private saveSuccessHelper(cityLocation?: CityLocation) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();

    if (this.isNewCity) {
      this.alertService.showMessage(
        'Success',
        `City \"${cityLocation.city}\" was created successfully`,
        MessageSeverity.success
      );
    } else {
      this.alertService.showMessage(
        'Success',
        `Changes to city \"${cityLocation.city}\" were saved successfully`,
        MessageSeverity.success
      );
    }

    this.resetForm();
    this.router.navigate(['/settings/cities']);
  }

  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    if (error == '409') {
      this.duplicateCity = true;
      return;
    }
    this.alertService.showStickyMessage(
      'Save Error',
      'The below errors occurred whilst saving your changes:',
      MessageSeverity.error,
      error
    );
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  cancel() {
    this.resetForm();

    this.alertService.showMessage(
      'Cancelled',
      'Operation cancelled by user',
      MessageSeverity.default
    );

    this.router.navigate(['/settings/cities']);
  }

  resetForm() {
    this.cityForm.reset();
  }

  private getCountryName(id) {
    return this.countries.find(i => i.id == id).name;
  }
}
