import { Component, Input, OnInit, Inject } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Company } from 'src/app/company/models/company.model';
import { Industry } from 'src/app/shared/models/industry.model';
import {
  AlertService,
  MessageSeverity
} from 'src/app/shared/services/alert.service';
import { IndustryService } from 'src/app/shared/services/industry.service';
import { AppTranslationService } from 'src/app/shared/services/app-translation.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  CountryMaster,
  StateMaster
} from 'src/app/shared/models/country.model';
import { HelperService } from 'src/app/shared/services/helper.service';
import { Utilities } from 'src/app/shared/services/utilities';
import { Currency } from 'src/app/shared/models/currency.model';
import { DocumentService } from 'src/app/shared/services/document.service';
import { FormsHelperService } from 'src/app/shared/services/forms-helper.service';
import { Location } from '@angular/common';
import { CompanyService } from 'src/app/company/services/company.service';
import { StatusesEnum } from '../../../company/enums/statuses.enum';

@Component({
  selector: 'app-add-or-edit-company',
  templateUrl: './add-or-edit-company.component.html',
  styleUrls: ['./add-or-edit-company.component.scss']
})
export class AddOrEditCompanyComponent implements OnInit {
  @Input() company: Company;
  companyForm: UntypedFormGroup;
  isForEditting: boolean;
  serviceProviderId: any;
  countries: CountryMaster[] = [];
  currencies: Currency[] = [];
  selectedCountry: CountryMaster;
  industryId: number;
  industryList: Industry[] = [];
  isDialogOpened: boolean = false;
  isSaving: boolean = false;
  existingLogoName: string = '';
  logo: File = null;
  logoUploaded: boolean = false;
  selectedImage: any;
  cities: StateMaster[] = [];
  isFailedToSave: boolean = false;
  statusList: boolean[];
  statusEnum = StatusesEnum;

  constructor(
    private companyService: CompanyService,
    private industryService: IndustryService,
    private translationService: AppTranslationService,
    private alertService: AlertService,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private dialogRef: MatDialogRef<AddOrEditCompanyComponent>,
    private helperService: HelperService,
    private formsHelperService: FormsHelperService,
    private documentService: DocumentService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {}

  ngOnInit() {
    if (this.dialogData['company']) {
      this.isDialogOpened = true;
      this.company = this.dialogData['company'];
    }

    if (!this.isDialogOpened) {
      this.route.params.subscribe(params => {
        this.serviceProviderId = params['parentCompanyId'];
      });

      this.route.data.subscribe(data => {
        this.company = data['company'];
      });
    }

    this.getStatusList();
    this.getIndustries();

    this.isForEditting = this.company != null;
    this.company = this.company || ({} as Company);
    if (this.company.industry != null)
      this.industryId = this.company.industry.id;
    this.company.type = 'XplCustomer';

    if (this.company.logo) {
      this.existingLogoName = this.company.logo.substring(
        this.company.logo.lastIndexOf('_') + 1
      );

      this.selectedImage = this.company.logo;
    }

    this.getLookups();
    this.initForm();
  }

  getLookups() {
    this.getCountries();
    this.getCurrencies();
  }

  get hasNoLogo() {
    return this.company?.logo == null && this.logo == null;
  }

  save() {
    if (this.isSaving) {
      return;
    }

    if (!this.validateForm()) {
      this.isSaving = false;
      this.isFailedToSave = true;
      return;
    }

    this.isSaving = true;
    this.isFailedToSave = false;
    const formVal = this.companyForm.value;
    formVal.serviceProviderId = this.serviceProviderId;
    formVal.industryId =
      formVal.industryId == 'null' ? null : formVal.industryId;
    this.industryId = formVal.industryId;
    formVal.isCreditLimit = formVal.isCreditLimit ?? false;

    if (this.isForEditting) {
      formVal.id = this.company.id;
      this.updateCompany(formVal);
    } else {
      this.addNewCompany(formVal);
    }
  }

  validateForm(): boolean {
    return this.companyForm.valid;
  }

  addNewCompany(companyVal: Company) {
    this.companyService.addNewCompany(companyVal).subscribe(
      createdCompany => {
        this.alertService.success('Added!');
        this.company.id = createdCompany.id;
        this.uploadLogoAndGetUrl();
        if (createdCompany) {
          this.router.navigate(['/customer/', this.company.id, 'details']);
        }
      },
      error => {
        this.isSaving = false;
        this.alertService.error(`Error: ${error}`);
      }
    );
  }

  updateCompany(companyVal: Company) {
    this.companyService.updateCompany(companyVal).subscribe(
      data => {
        this.alertService.success('Saved!');
        this.uploadLogoAndGetUrl();
        this.isSaving = true;
        setTimeout(() => {
          if (this.isDialogOpened) {
            companyVal.logo = this.company.logo;
            companyVal.industry = this.industryList.find(
              i => i.id == this.industryId
            );

            this.companyService.companyUpdatedEmitter.emit({
              updatedCompany: companyVal
            });

            this.isSaving = false;
            this.closeDialog();
          } else {
            if (this.isFreePLCompany) {
              this.router.navigate([
                '/company-profile/',
                this.company.id,
                'details'
              ]);
            } else {
              this.isCustomerProfile
                ? this.router.navigate([
                    '/customer/',
                    this.company.id,
                    'details'
                  ])
                : this.router.navigate(['/company/', this.company.id]);
            }
          }
        }, 1500);
      },
      error => {
        this.isSaving = false;
        this.alertService.error(`Error: ${error}`);
      }
    );
  }

  initForm() {
    this.companyForm = this.fb.group({
      name: [this.company.name, [Validators.required, Validators.minLength(2)]],
      companyOfficialName: [
        this.company.companyOfficialName,
        [Validators.required]
      ],
      industryId: [this.industryId, [Validators.required]],
      phoneNumber: [this.company.phoneNumber, [Validators.minLength(2)]],
      email: [this.company.email, [Validators.email]],
      address: [this.company.address],
      logo: [this.company.logo],
      type: [this.company.type],
      companyRegistrationNo: [this.company.companyRegistrationNo],
      vatNumber: [this.company.vatNumber],
      countryId: [this.company.countryId],
      cityId: [this.company.cityId],
      isCreditLimit: [this.company.isCreditLimit],
      creditLimit: [this.company.creditLimit],
      currencyId: [this.company.currencyId],
      website: [this.company.website],
      isActive: [this.company?.isActive, [Validators.required]]
    });
  }

  cancel() {
    this.isDialogOpened ? this.closeDialog() : this.location.back();
  }

  getIndustries() {
    this.industryService.getAll().subscribe(data => {
      this.industryList = data;
    });
  }

  getCountries() {
    this.helperService.getCountries().subscribe(
      data => {
        this.countries = data;
        if (this.company != null) {
          this.selectedCountry = this.countries.find(
            e => e.id == this.company.countryId
          );
          this.onChangeCountry(this.selectedCountry, false);
        }
      },
      error => {
        this.alertService.showStickyMessage(
          'Error',
          `An error occured whilst retreiving a list of countries.\r\nError: "${Utilities.getHttpResponseMessages(
            error
          )}"`,
          MessageSeverity.error,
          error
        );
      }
    );
  }

  getCurrencies() {
    this.helperService.getCurrencies().subscribe(
      data => {
        this.currencies = data;
        this.initCurrencyDDL();
      },
      error => {
        this.alertService.showStickyMessage(
          'Error',
          `An error occured whilst retreiving a list of currencies.\r\nError: "${Utilities.getHttpResponseMessages(
            error
          )}"`,
          MessageSeverity.error,
          error
        );
      }
    );
  }

  getStatusList(): void {
    this.statusList = [true, false];
  }

  initCurrencyDDL(): void {
    let selectedCurrencyId = this.company?.currencyId;

    if (!selectedCurrencyId) {
      this.companyForm?.get('currencyId')?.patchValue(this.egpCurrencyId);
    }
  }

  onChangeCountry(event, isCountryChanged: boolean) {
    this.selectedCountry = event;
    this.cities = this.selectedCountry?.states;
    if (isCountryChanged) {
      this.companyForm.get('cityId').reset();
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  creditLimitChecked(event: any) {
    if (event?.checked === true) {
      this.setValidators('currencyId', this.companyForm);
      this.setValidators('creditLimit', this.companyForm);

      this.throwError(this.companyForm.get('creditLimit'));
    } else {
      this.formsHelperService.clearValidator(this.companyForm, 'currencyId');
      this.formsHelperService.clearValidator(this.companyForm, 'creditLimit');
      this.companyForm.get('currencyId').reset();
      this.companyForm.get('creditLimit').reset();
    }
  }

  throwError(control: AbstractControl) {
    control.setErrors(Validators.required);
  }

  setValidators(
    controlName: string,
    formGroup: UntypedFormGroup,
    otherValidators: any = null
  ) {
    if (otherValidators) {
      formGroup.controls[controlName].setValidators([
        Validators.required,
        otherValidators
      ]);
    } else {
      formGroup.controls[controlName].setValidators(Validators.required);
    }
  }

  onChangeLogo(event) {
    this.logo = event.target.files[0];

    if (!this.logo.type.match(`image.*|image/png|TIFF|PSD|SVG|PJP`)) {
      this.alertService.error(
        'File type not supported. Supported formats are: jpg, jpeg, png, tiff, psd, svg, bmp, webp, pjp.'
      );
      return;
    }

    if (this.logo.size / 1024 > 30) {
      this.alertService.error(
        'File size not supported. Supported size is up to 30KB.'
      );
      return;
    }

    this.formsHelperService.clearValidator(this.companyForm, 'logo');
    this.logoUploaded = true;

    const reader = new FileReader();
    reader.onload = () => {
      this.selectedImage = reader.result;
    };
    reader.readAsDataURL(this.logo);
  }

  uploadLogoAndGetUrl() {
    if (!this.logo) {
      return;
    }

    const formData = this.prepareFormData(this.logo);

    this.isSaving = true;
    this.documentService.UploadCompanyLogo(formData).subscribe(data => {
      this.company.logo = data.documentPath;
      this.logoUploaded = true;
      this.isSaving = false;
    });
  }

  prepareFormData(file: File) {
    const formData = new FormData();
    formData.append('submittedFile', file);
    formData.append('companyId', this.company?.id.toString());
    return formData;
  }

  onUploadClick() {
    document.getElementById('logo').click();
  }

  get egpCurrencyId(): number {
    let egpCurrency = this.currencies?.find(c => c.code === 'EGP');
    return egpCurrency?.id;
  }

  isFormControlSelected(input: any, formControlName: string): boolean {
    return this.companyForm.get(formControlName).value === input;
  }

  get isFreePLCompany(): boolean {
    return this.company?.id == 1;
  }

  get errorMesage(): string {
    return `Required fields are either left empty or filled incorrectly 
            . Check all fields highlighted in red and try again.`;
  }

  get isCustomerProfile(): boolean {
    return (
      this.router.url.includes('customer') ||
      !this.isForEditting ||
      !this.isFreePLCompany
    );
  }
}
