import { FreePLUser } from './freepl-user.model';
import { ActivityLog } from './shipment/activity-log.model';
import { Form4Request } from './shipment/form4-request.model';
import { Progress } from './shipment/progress.model';
import { ShipmentBase } from './shipment/shipmentBase.model';
import { ShipmentRouting } from './shipment/shipmentRouting.model';
import { ShipmentServices } from './shipment/shipmentServices.model';
import { Status } from './shipment/status.model';

export class Shipment implements ShipmentBase {
  cargoDetails: any;
  documents: any[];
  id: string;
  routing: ShipmentRouting;
  shipmentServices: ShipmentServices;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
  createdDatetime?: Date;
  updatedDatetime?: Date;
  companyId: string;
  externalId?: string;
  submittedDatetime: Date;
  freePlShipmentNumberInt: number;
  freePlShipmentNumberString: string;
  shipmentStatus?: Status;
  shipmentProgress?: Progress;
  lastTwoVoidActivityLogs: ActivityLog[];
  lastActivityLog: ActivityLog;
  lastActiveActivityLog: ActivityLog;
  isDuplicated?: boolean;
  duplicatedFrom?: string;
  draftLabel?: string;
  marker?: string;
  hasPendingActions?: boolean;
  form4Request?: Form4Request;
  isForm4HandledByCustomer?: boolean;
  isReadyForInvoicePreparation?: boolean;
  isReadyForInvoiceIssuance?: boolean;
  submittedBy?: FreePLUser;
  quoteId?: string;
  setPriorityBy?: FreePLUser;

  public assign(values: Object = {}) {
    Object.assign(this, values);
  }
}
