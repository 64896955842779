import { HttpClient } from '@angular/common/http';
import { Injectable, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ShipmentFreightType } from '../../shared/models/enums/shipment-freight-type.enum';
import { Port } from '../../shared/models/port.model';
import { PortDto } from '../models/portDto.model';
import { TransportTypeEnum } from 'src/app/shared/models/enums/transport-type.enum';
import { OriginAndDestinationPorts } from '../../shared/models/origin-and-destination-ports.model';

@Injectable({
  providedIn: 'root'
})
export class PortService {
  baseURL = environment.apiUrl + 'port';

  @Output() portUpdatedEmitter: EventEmitter<{
    updatedPort: PortDto;
  }> = new EventEmitter<{ updatedPort: PortDto }>();

  @Output() portAddedEmitter: EventEmitter<{
    addedPort: PortDto;
  }> = new EventEmitter<{ addedPort: PortDto }>();

  constructor(private http: HttpClient) {}

  getById(portId: number): Observable<PortDto> {
    return this.http.get<PortDto>(`${this.baseURL}/${portId}`);
  }

  filterPorts(
    searchKeyword: string,
    portType: string,
    countryId?: number,
    excludedCountry?: number,
    cityId?: number
  ): Observable<Port[]> {
    return this.http.get<Port[]>(
      `${
        this.baseURL
      }/FilterdPorts?countryId=${countryId}&searchKeyword=${searchKeyword}&isAir=${
        portType == ShipmentFreightType.Air ? 'true' : 'false'
      }&excludedCountry=${excludedCountry}&cityId=${cityId}&all=${
        portType == TransportTypeEnum.Trucking ? 'true' : 'false'
      }`
    );
  }

  searchPorts(
    searchKeyword: string,
    freightReference: string = '',
    inEgypt: boolean = false,
    pageIndex = 0,
    pageSize = 10
  ): Observable<PortDto[]> {
    return this.http.get<PortDto[]>(
      this.baseURL +
        `/Search?searchKeyword=${searchKeyword}&freightReference=${freightReference}&inEgypt=${inEgypt}&pageIndex=${pageIndex}&pageSize=${pageSize}`
    );
  }

  addNewPort(port: PortDto): Observable<PortDto> {
    return this.http.post<PortDto>(this.baseURL, port);
  }

  deletePort(port: PortDto): Observable<boolean> {
    return this.http.delete<boolean>(
      this.baseURL + `/${port.id}/${port.isAirPort}`
    );
  }

  updatePort(id: number, port: PortDto): Observable<PortDto> {
    return this.http.put<PortDto>(`${this.baseURL}/${id}`, port);
  }

  getPorts(countryId: number, isAir: boolean, searchKeyword = '') {
    return this.http.get<Port[]>(
      `${this.baseURL +
        '/Country'}/${countryId}?searchKeyword=${searchKeyword}&isAir=${isAir}`
    );
  }

  getOriginAndDestinationPorts(
    originPortId: number,
    destinationPortId: number
  ): Observable<OriginAndDestinationPorts> {
    return this.http.get<OriginAndDestinationPorts>(
      `${this.baseURL}/OriginAndDestinationPort?originPortId=${originPortId}&destinationPortId=${destinationPortId}`
    );
  }

  getPortsInLandCountries(searchKeyword: string): Observable<PortDto[]> {
    return this.http.get<PortDto[]>(
      `${this.baseURL}/LandCountryPort?searchKeyword=${searchKeyword}`
    );
  }
}
