import { ServiceTypes } from 'src/app/shared/models/shipment/service-types.enum';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { QuoteBase } from 'src/app/shared/quote/models/quote-base.model';
import { QuoteDocument } from 'src/app/shared/quote/models/quote-document.model';
import { QuoteRoutingDetails } from 'src/app/shared/quote/models/quote-routing-details.model';
import { QuoteServices } from 'src/app/shared/quote/models/quote-services.model';
import { QuoteCargoDetails } from 'src/app/shared/quote/models/quote-cargo-details.model';

@Injectable({
  providedIn: 'root'
})
export class CreateQuoteService {
  baseURL = environment.apiUrl + 'Quote';

  private _quoteBase: QuoteBase;
  egyptId: number;

  constructor(private http: HttpClient) {}

  get quote() {
    return this._quoteBase;
  }

  assignQuoteObject(quote: QuoteBase) {
    this._quoteBase = quote;
  }

  assignDocument(document: QuoteDocument) {
    if (!this._quoteBase.documents) {
      this._quoteBase.documents = [];
    }
    this._quoteBase.documents.push(document);
  }

  assignRoutingDetails(routingDetails: QuoteRoutingDetails) {
    this._quoteBase.routingDetails = routingDetails;
  }

  assignCargoDetails(cargoDetails: QuoteCargoDetails) {
    this._quoteBase.cargoDetails = cargoDetails;
  }

  addIorToServices() {
    this._quoteBase!.quoteServices!.importExport = {
      serviceType: ServiceTypes.IOR
    };
  }

  addEorToServices() {
    this._quoteBase!.quoteServices!.importExport = {
      serviceType: ServiceTypes.EOR
    };
  }

  clearImportExportServices() {
    this._quoteBase!.quoteServices!.importExport = null;
  }

  clearLandServices() {
    this._quoteBase!.quoteServices!.freight.isLand = null;
  }

  addOrDeleteCustomsClearanceService(isForAdd: boolean) {
    this._quoteBase.quoteServices.customsClearance = { isSelected: isForAdd };
  }

  addTruckingToServices(isImport: boolean) {
    this._quoteBase!.quoteServices!.trucking = {
      truckingType: !isImport
        ? ServiceTypes.DoorToPort
        : ServiceTypes.PortToDoor
    };
  }

  clearTruckingService() {
    this._quoteBase!.quoteServices!.trucking = null;
  }

  initiateQuoteWithService(
    services: QuoteServices,
    companyId: string
  ): Observable<QuoteBase> {
    return this.http.post<QuoteBase>(
      `${this.baseURL}/Service?companyId=${companyId}`,
      services
    );
  }

  updateRoutingDetails(
    quoteId: string,
    routing: QuoteRoutingDetails
  ): Observable<QuoteBase> {
    return this.http.put<QuoteBase>(
      `${this.baseURL}/Routing?quoteId=${quoteId}`,
      routing
    );
  }

  updateCargoDetails(
    quoteId: string,
    cargo: QuoteCargoDetails
  ): Observable<QuoteBase> {
    return this.http.put<QuoteBase>(
      `${this.baseURL}/Cargo?quoteId=${quoteId}`,
      cargo
    );
  }

  removeDocument(quoteId: string, documentSqlId: string): Observable<any> {
    return this.http.delete<any>(
      `${this.baseURL}/Document/${documentSqlId}?quoteId=${quoteId}`
    );
  }

  updateDocumentNotes(quoteId: string, documentSqlId: number, note: string) {
    return this.http.put<any>(
      `${this.baseURL}/${quoteId}/Documents/${documentSqlId}/Note/${note}`,
      null
    );
  }

  getQuoteById(quoteId: string, companyId: string): Observable<QuoteBase> {
    return this.http.get<QuoteBase>(
      `${this.baseURL}/${quoteId}/Company/${companyId}`
    );
  }

  submitQuote(
    quoteId: string,
    isCustomsClearanceSelected?: boolean
  ): Observable<string> {
    return this.http.post<string>(
      `${this.baseURL}/${quoteId}/AdditionalCustomsClearanceService/${isCustomsClearanceSelected}`,
      []
    );
  }
}
