<div class="row search-bar-container mx-0">
  <div class="col-8 d-flex pl-0">
    <div class="btn-container" *ngIf="isDisplaySelectAllCheckBox">
      <mat-checkbox
        class="search-btn-primary"
        (change)="onSelectAll($event)"
        [checked]="isAllSelected && isEmptyList"
      >
        <div class="checkbox-title">
          Select All
        </div>
      </mat-checkbox>
    </div>

    <div class="btn-container">
      <button
        class="search-btn-primary d-flex"
        (click)="showDraftShipments(true)"
        *ngIf="isShowDraftButton"
      >
        <div>
          <svg-icon name="draftShipmentsFilter"></svg-icon>
        </div>
        <div class="draft-btn">
          Drafts
        </div>
      </button>
    </div>

    <div class="btn-container">
      <button
        class="search-btn-primary"
        (click)="showDraftShipments(false)"
        *ngIf="isShowingDraftShipments && isCurrentModule(moduleName.Shipment)"
      >
        <svg-icon name="smallSortFilterIcon"></svg-icon>
        Ongoing Shipments
      </button>
    </div>

    <div class="btn-container">
      <button
        class="search-btn-primary"
        (click)="openColumnsToSortBy()"
        [ngClass]="{ 'border-primary': isDialogSortByOpen }"
        *ngIf="!isShowingDraftShipments"
        #columnsToSortByButton
      >
        <svg-icon name="smallSortFilterIcon"></svg-icon>
        Sorting
        <span class="ng-arrow-wrapper pl-2">
          <span class="ng-arrow"></span>
        </span>
      </button>
    </div>

    <div class="btn-container">
      <button
        class="search-btn-primary"
        (click)="openFilters()"
        [ngClass]="{ 'border-primary': isDialogFilterOpen }"
        *ngIf="!isShowingDraftShipments"
        #filtersButton
      >
        <svg-icon name="smallFilterIcon"></svg-icon>
        Filters

        <span
          *ngIf="numberOfSelectedFilters"
          class="selected-filters-number-container"
          >{{ numberOfSelectedFilters }}</span
        >
        <span class="ng-arrow-wrapper pl-2">
          <span class="ng-arrow"></span>
        </span>
      </button>
    </div>
    <div class="btn-container">
      <button
        class="search-btn-primary cursor-pointer"
        (click)="openActions()"
        [ngClass]="{ 'border-primary': isDialogActionOpen }"
        *ngIf="canOpenActionsList"
        #actionsButton
        [disabled]="!isCardsSelected"
        placement="bottom"
        [ngbPopover]="noSelection"
        triggers="mouseenter:mouseleave"
        [disablePopover]="isCardsSelected"
      >
        <!-- disablePopover (targerModule == moduleName.Approval) condition will be removed when the popup is implemented -->
        <svg-icon name="actionIcon"></svg-icon>
        Actions
        <span class="ng-arrow-wrapper pl-2">
          <span class="ng-arrow"></span>
        </span>
      </button>

      <ng-template #noSelection>
        <div class="popover">{{ noSelectionMessage }}</div>
      </ng-template>
    </div>

    <div class="btn-container">
      <button
        class="search-btn-primary"
        *ngIf="
          selectedCardView == cardViewEnum.Table &&
          (isCurrentModule(moduleName.Registration) ||
            isCurrentModule(moduleName.ProductLibrary) ||
            isCurrentModule(moduleName.Quote) ||
            (isCurrentModule(moduleName.Shipment) && !isShowingDraftShipments))
        "
        (click)="openColumnsFilter()"
        [ngClass]="{ 'border-primary': isDialogManageColumnOpen }"
        #columnsFilterButton
      >
        <svg-icon name="manageColumn"></svg-icon>

        Manage Columns
        <span class="ng-arrow-wrapper pl-2">
          <span class="ng-arrow"></span>
        </span>
      </button>
    </div>
  </div>
  <div class="search-container">
    <div class="btn-container search-input-container">
      <input
        type="text"
        class="search-input"
        [placeholder]="searchBoxPlaceHolder"
        [(ngModel)]="
          isCurrentModule(moduleName.Shipment)
            ? searchCriteria.searchTerm
            : searchCriteria.productSearchKeyword
        "
        (keyup)="onSearchChange($event)"
      />
      <svg-icon name="searchIcon" class="search-icon"></svg-icon>
    </div>

    <div class="actions" *ngIf="!isDisableListViewButtons">
      <mat-button-toggle-group name="listView" [value]="selectedCardView">
        <mat-button-toggle
          value="CompactList"
          (change)="changeListView($event.value)"
          placement="bottom"
          [ngbPopover]="compactListTemplate"
          triggers="mouseenter:mouseleave"
        >
          <svg-icon
            [name]="
              selectedCardView == cardViewEnum.Compact
                ? 'dimmedCompactCardView'
                : 'blueCompactCardView'
            "
          ></svg-icon>
        </mat-button-toggle>
        <mat-button-toggle
          value="TableList"
          (change)="changeListView($event.value)"
          placement="bottom"
          [ngbPopover]="tableListTemplate"
          triggers="mouseenter:mouseleave"
        >
          <svg-icon
            name="blueListView"
            [name]="
              selectedCardView == cardViewEnum.Table
                ? 'dimmedListView'
                : 'blueListView'
            "
          ></svg-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
</div>
<ng-template #compactListTemplate>
  <div class="row pop-over">
    <div class="col-4 px-0 pop-over-label">Compact card view</div>
  </div>
</ng-template>
<ng-template #tableListTemplate>
  <div class="row pop-over">
    <div class="col-4 px-0 pop-over-label">List view</div>
  </div>
</ng-template>
