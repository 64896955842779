<div class="reduced-font">
  <div>
    <carousel interval="6000">
      <slide>
        <img src="assets/images/demo/banner1.png" alt="ASP.NET" class="img-fluid" />
        <div class="carousel-caption">
          <p>
            Learn how to build ASP.NET apps that can run anywhere
            <a class="btn btn-outline-info btn-sm" href="http://go.microsoft.com/fwlink/?LinkID=525028&clcid=0x409" target="_blank">
              Learn More
            </a>
          </p>
        </div>
      </slide>
      <slide>
        <img src="assets/images/demo/banner2.png" alt="Visual Studio" class="img-fluid" />
        <div class="carousel-caption">
          <p>
            One platform for building modern web, native mobile and native desktop applications
            <a class="btn btn-outline-primary btn-sm" href="http://angular.io" target="_blank">
              Learn More
            </a>
          </p>
        </div>
      </slide>
      <slide>
        <img src="assets/images/demo/banner3.png" alt="Package Management" class="img-fluid" />
        <div class="carousel-caption">
          <p>
            Bring in libraries from NuGet and npm, and bundle with angular/cli
            <a class="btn btn-outline-success btn-sm" href="http://go.microsoft.com/fwlink/?LinkID=525029&clcid=0x409" target="_blank">
              Learn More
            </a>
          </p>
        </div>
      </slide>
      <slide>
        <img src="assets/images/demo/banner4.png" alt="Eben Monney" class="img-fluid" />
        <div class="carousel-caption">
          <p>
            Follow me on social media for updates and tips on using this startup project
            <a class="btn btn-outline-secondary btn-sm" href="https://www.ebenmonney.com/about" target="_blank">
              Learn More
            </a>
          </p>
        </div>
      </slide>
    </carousel>
  </div>


  <div class="row">
    <div class="col-md-3">
      <h3>Application uses</h3>
      <ul>
        <li>Restful API backend using the cross platform <a href="http://go.microsoft.com/fwlink/?LinkID=525028&clcid=0x409" target="_blank">ASP.NET Core MVC</a></li>
        <li>Client framework with <a href="http://angular.io" target="_blank">Angular 11</a> and typescript</li>
        <li><a href="http://npmjs.com" target="_blank">npm</a> for managing client-side libraries</li>
        <li>Theming using <a href="http://go.microsoft.com/fwlink/?LinkID=398939" target="_blank">Bootstrap</a></li>
        <li>Module bundling with <a href="https://cli.angular.io" target="_blank">Angular CLI</a></li>
      </ul>
    </div>
    <div class="col-md-3">
      <h3>How to</h3>
      <ul>
        <li><a href="https://www.ebenmonney.com/quickapp#adding-pages" target="_blank">Add additional pages and set access permissions</a></li>
        <li><a href="http://go.microsoft.com/fwlink/?LinkId=699315" target="_blank">Manage User Secrets using Secret Manager.</a></li>
        <li><a href="http://go.microsoft.com/fwlink/?LinkId=699316" target="_blank">Use logging to log a message.</a></li>
        <li><a href="http://go.microsoft.com/fwlink/?LinkId=699317" target="_blank">Add packages using NuGet.</a></li>
        <li><a href="https://www.ebenmonney.com/quickapp#adding-vendor-packages" target="_blank">Add client packages using npm</a></li>
        <li><a href="https://www.ebenmonney.com/quickapp#building-vendor-packages" target="_blank">Rebuild client vendor packages</a></li>
        <li><a href="http://go.microsoft.com/fwlink/?LinkId=699319" target="_blank">Target development, staging or production environment.</a></li>
      </ul>
    </div>
    <div class="col-md-3">
      <h3>Overview</h3>
      <ul>
        <li><a href="http://go.microsoft.com/fwlink/?LinkId=518008" target="_blank">Conceptual overview of what is ASP.NET Core</a></li>
        <li><a href="https://angular.io/guide/quickstart" target="_blank">QuickStart guide to Angular 11</a></li>
        <li><a href="http://go.microsoft.com/fwlink/?LinkId=398602" target="_blank">Working with Data</a></li>
        <li><a href="http://go.microsoft.com/fwlink/?LinkId=398603" target="_blank">Security</a></li>
        <li><a href="http://go.microsoft.com/fwlink/?LinkID=699321" target="_blank">Client side development</a></li>
        <li><a href="https://github.com/angular/angular-cli/wiki" target="_blank">Angular CLI documentation</a></li>
        <li><a href="http://go.microsoft.com/fwlink/?LinkID=699322" target="_blank">Develop on different platforms</a></li>
        <li><a href="https://www.ebenmonney.com/quickapp" target="_blank">Read more on the documentation site</a></li>
      </ul>
    </div>
    <div class="col-md-3">
      <h3>Run & Deploy</h3>
      <ul>
        <li><a href="https://www.ebenmonney.com/quickapp#how-to-run-app" target="_blank">Run your app</a></li>
        <li><a href="http://go.microsoft.com/fwlink/?LinkID=517853" target="_blank">Run tools such as EF migrations and more</a></li>
        <li><a href="http://go.microsoft.com/fwlink/?LinkID=398609" target="_blank">Publish to Microsoft Azure Web Apps</a></li>
      </ul>
    </div>
  </div>
</div>
