import { Inject, Injectable } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileViewerComponent } from './file-viewer.component';

@Injectable({
  providedIn: 'root'
})
export class FileViewerService {
  constructor(private dialog: MatDialog) {}

  view(url) {
    this.dialog.open(FileViewerComponent, {
      hasBackdrop: true,
      width: '95vw',
      height: '90vh',
      data: { url: url }
    });
  }
}
