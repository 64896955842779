<div>
  <div class="row control-box">
    <div class="col-lg-8">
      <div class="form-group search-box">
        <app-search-box (searchChange)="onSearchChanged($event)" placeholder="{{'roles.management.Search' | translate}}">></app-search-box>
      </div>
    </div>
    <div class="col-lg-4 pr-lg-5">
      <ul class="nav flex-column flex-lg-row justify-content-end">
        <li *ngIf="canCreateRole" class="nav-item toolbaritem">
          <a class="nav-link" href="javascript:;" [routerLink]="'roles/add'">
            <i class="fa fa-plus"></i> {{'roles.management.NewRole' | translate}}
          </a>
        </li>
      </ul>
    </div>
  </div>

  <ngx-datatable class="material colored-header sm table table-striped table-hover"
                 [loadingIndicator]="loadingIndicator"
                 [rows]="rows"
                 [rowHeight]="35"
                 [headerHeight]="35"
                 [footerHeight]="35"
                 [columns]="columns"
                 [scrollbarV]="true"
                 [columnMode]="'force'">
  </ngx-datatable>

  <ng-template #indexTemplate let-value="value">
    <strong>{{value}}</strong>
  </ng-template>


  <ng-template #actionsTemplate let-row="row" let-value="value" let-i="index">
    <a *ngIf="canUpdateRole" class="btn btn-link btn-sm" href="javascript:;" [routerLink]="['roles/' + row.id + '/edit']"><i class="fa fa-pencil-square-o" aria-hidden="true"></i> {{'roles.management.Edit' | translate}}</a>
    {{ canUpdateRole && canDeleteRole ? "|" : "" }}
    <a *ngIf="canDeleteRole" class="btn btn-link btn-sm" href="javascript:;" (click)="deleteRole(row)"><i class="fa fa-trash-o" aria-hidden="true"></i> {{'roles.management.Delete' | translate}}</a>
  </ng-template>
</div>
