<br />
<div class="d-flex justify-content-between">
  <app-page-header [title]="'Registrations'"></app-page-header>
  <button
    *ngIf="canCreateProductRegistration"
    class="request-registration"
    [routerLink]="'./create-product-registration'"
  >
    <span class="plus">+</span>
    <span class="request-registration-btn ">New Registration</span>
  </button>
</div>
<div class="registration-list-container">
  <app-g-search-bar
    [tableViewColumns]="tableViewColumns"
    [columnsToSortBy]="productsSortFilters"
    [columnsFilter]="employeeSelectedColumnsNames"
    [hasItems]="productsPaginated?.items?.length > 0"
    [targerModule]="moduleName.Registration"
    [listFilters]="listFilters"
    (onSelectAllChange)="onSelectAllChange($event)"
    [selectedCardView]="selectedCardView"
  ></app-g-search-bar>

  <div>
    <mat-tab-group
      animationDuration="0ms"
      (selectedTabChange)="selectViewTabFilter($event.tab.textLabel)"
    >
      <mat-tab label="All">
        <ng-template matTabContent>
          <ng-template [ngTemplateOutlet]="registrationView"></ng-template>

          <div *ngIf="productsPaginated?.totalItems === 0">
            <div class="no-registrations-container text-center">
              <svg-icon name="noItemsIcon"></svg-icon>
              <p class="no-registration-message">
                Your requests will be listed here
              </p>
            </div>
          </div>
        </ng-template>
      </mat-tab>

      <mat-tab [label]="registrationTabs.PendingAction">
        <ng-template matTabContent>
          <ng-template [ngTemplateOutlet]="registrationView"></ng-template>

          <div *ngIf="productsPaginated?.totalItems === 0">
            <div class="no-registrations-container text-center">
              <svg-icon name="noItemsIcon"></svg-icon>
              <p class="no-registration-message">
                Your requests will be listed here
              </p>
            </div>
          </div>
        </ng-template>
      </mat-tab>

      <mat-tab [label]="registrationTabs.AcceptanceNoReceived">
        <ng-template matTabContent>
          <ng-template [ngTemplateOutlet]="registrationView"></ng-template>

          <div *ngIf="productsPaginated?.totalItems === 0">
            <div class="no-registrations-container text-center">
              <svg-icon name="noItemsIcon"></svg-icon>
              <p class="no-registration-message">
                Your requests will be listed here
              </p>
            </div>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<ng-template #registrationView>
  <app-product-registration-table-view
    [productsList]="productsPaginated?.items"
    [isAllSelected]="isAllSelected"
    [columns]="employeeSelectedColumns"
  ></app-product-registration-table-view>
</ng-template>

<div class="paginator-bar mt-3">
  <mat-paginator
    [length]="productsPaginated?.totalItems"
    [pageSize]="productsPaginated?.pageSize"
    [pageIndex]="productsPaginated?.pageIndex"
    (page)="handlePageEvent($event)"
    aria-label="Select page"
  >
  </mat-paginator>
</div>
