import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CanDeactivate, Router } from '@angular/router';
import { UploadDocsComponent } from '../pages/upload-docs/upload-docs.component';
import { ShipmentsService } from '../../shipments/shipments.service';
import { UnSavedDataNotificationComponent } from '../../shipments/sub-components/un-saved-data-notification/un-saved-data-notification.component';

@Injectable()
export class PreventUnsavedChanges
  implements CanDeactivate<UploadDocsComponent> {
  constructor(
    private router: Router,
    private shipmentService: ShipmentsService,
    private dialog: MatDialog
  ) {}

  canDeactivate(component: any): boolean {
    if (component?.formDirty) {
      this.dialog.open(UnSavedDataNotificationComponent, {
        hasBackdrop: true,
        width: '600px',
        maxHeight: '675px',
        data: {
          message: component.unsavedDataConfirmationMessage
        }
      });

      this.shipmentService.confirmUnSavedData.subscribe(data => {
        this.dialog.closeAll();
        component.uploadForm.reset();
        this.router.navigate([component.redirectionURL], {
          relativeTo: component.activeRoute
        });
      }); 

      return false;
    } else {
      return true;
    }
  }
}
