<div class="product-variants-container">
  <div class="headings col-12 px-0">
    <div class="column-title col-2">Variant(s)</div>
    <div class="column-title col-3">
      Product Description
    </div>
    <div class="col-2 px-0"></div>
    <div class="column-title col-2">Country of Origin</div>
    <div class="column-title col-2">
      Actual Manufacturer
    </div>
    <div class="col-1 px-0"></div>
  </div>

  <div *ngFor="let variant of variants" class="d-flex">
    <div class="column-content part-number col-2 p-0">
      {{ getVariantPartNumber(variant) }}
    </div>
    <div class="column-content col-3 p-0">
      {{ getVariantDescription(variant) }}
    </div>
    <div class="col-2 p-0"></div>
    <div class="column-content country-content col-2 p-0">
      {{ getVariantCountryOfOrigin(variant) }}
    </div>
    <div class="column-content col-2 p-0">
      <span class="country-content">Manufacturer Name</span>
      <div class="column-content manufacturer-name">
        {{ getActualManufacturerName(variant) }}
      </div>
    </div>
  </div>
</div>
