import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApprovalBase } from 'src/app/approvals/models/approval-base.model';
import { FileViewerService } from 'src/app/shared/components/file-viewer/file-viewer.service';

@Component({
  selector: 'app-approval-summary',
  templateUrl: './approval-summary.component.html',
  styleUrls: ['./approval-summary.component.scss']
})
export class ApprovalSummaryComponent implements OnInit {
  @Input() approvalDetails: ApprovalBase;
  @Input() isDisplayDocumentSection: boolean;
  @Input() isDetailsPostCreation: boolean = false;
  @Input() redirectionRoute: string = null;

  constructor(
    private fileViewerService: FileViewerService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {}

  viewFile(path): void {
    if (this.approvalDetails?.invoiceDocument) {
      this.fileViewerService.view(path);
    }
  }
  redirectToProduct(product) {
    if (this.redirectionRoute) {
      this.router.navigate(
        [
          `${this.redirectionRoute}/products-library/${product?.productId}/product-details`
        ],
        { relativeTo: this.route }
      );
    } else if (this.isEditRoute) {
      this.router.navigate(
        [`../../../products-library/${product?.productId}/product-details`],
        { relativeTo: this.route }
      );
    } else {
      this.router.navigate(
        [`../../products-library/${product?.productId}/product-details`],
        { relativeTo: this.route }
      );
    }
  }
  get isEditRoute(): boolean {
    return this.router.url.includes('edit');
  }
}
