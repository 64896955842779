<div class="summary-container w-100">
  <div class="approval-information-container">
    <div class="row m-0">
      <div class="m-0 section-header">
        Approval Information
      </div>
      <div class="col-10 p-0 m-0">
        <div class="d-flex">
          <div class="field-section">
            <div class="label">Approval Type</div>
            <div class="value">
              {{ approvalDetails?.approvalType?.name || "-" }}
            </div>
          </div>

          <div
            class="field-section"
            *ngIf="
              isDetailsPostCreation
                ? approvalDetails?.approvalTypeReason?.approvalTypeId > 0
                : approvalDetails?.approvalType?.approvalTypeReasons?.length > 0
            "
          >
            <div class="label">Approval Required for</div>
            <div class="value">
              {{ approvalDetails?.approvalTypeReason?.name || "-" }}
            </div>
          </div>
        </div>

        <div>
          <div class="field-section">
            <div class="label">Category Classification</div>
            <div class="value">
              {{ approvalDetails?.categoryClassification || "-" }}
            </div>
          </div>
        </div>

        <div *ngIf="!isDetailsPostCreation">
          <div class="field-section pb-0">
            <div class="label">Selected Product</div>
            <div class="product-values">
              <div *ngIf="approvalDetails?.products?.legnth <= 0" class="value">
                -
              </div>
              <div
                class="value"
                *ngFor="let product of approvalDetails?.products"
              >
                <a
                  class="view-details"
                  (click)="redirectToProduct(product)"
                  target="_blank"
                >
                  {{ product?.productName || "-" }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="divider-row" *ngIf="isDisplayDocumentSection"></div>

    <!-- Documents Section -->
    <div class="row m-0" *ngIf="isDisplayDocumentSection">
      <div class="m-0 section-header">
        Uploaded Documents
      </div>
      <div class="col-10 p-0 m-0 ">
        <div class="field-section pb-0">
          <div class="label">Invoice</div>
          <div
            class="value"
            [ngClass]="{
              'view-details': approvalDetails?.invoiceDocument
            }"
            (click)="viewFile(approvalDetails?.invoiceDocument?.path)"
          >
            {{ approvalDetails?.invoiceDocument?.fileName || "-" }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
