import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NumericValidators } from 'src/app/shared/services/validators/numeric-validators.service';
import { Currency } from 'src/app/shared/models/currency.model';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AppTranslationService } from 'src/app/shared/services/app-translation.service';
import { CurrencyService } from '../../../services/currency.service';

@Component({
  selector: 'app-add-edit-currency',
  templateUrl: './add-edit-currency.component.html',
  styleUrls: ['./add-edit-currency.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddEditCurrencyComponent implements OnInit {
  currencyForm: UntypedFormGroup;
  isSaving: boolean = false;
  currency: Currency;
  isEditingMode: boolean;
  isDoublicatedCurrency: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private currencyService: CurrencyService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddEditCurrencyComponent>
  ) {}

  ngOnInit(): void {
    if (this.data !== undefined && this.data?.currency !== undefined) {
      this.currency = this.data['currency'];
      this.isEditingMode = true;
    }
    this.intializeForm();
  }

  intializeForm() {
    this.currency = this.currency || ({} as Currency);
    this.currencyForm = this.fb.group({
      name: [this.currency.name, [Validators.required]],
      symbol: [this.currency.symbol, [Validators.required]],
      code: [this.currency.code, [Validators.required]],
      egpExchangeRate: [
        this.currency.egpExchangeRate,
        [Validators.required, NumericValidators.decimalAmount]
      ]
    });
  }

  save() {
    this.isSaving = true;
    const formValues = this.currencyForm.value;
    if (!this.currencyForm.valid) {
      this.alertService.error('Invalid Form!');
      return;
    }

    if (this.isEditingMode) {
      this.updateRequest(formValues);
    } else {
      this.addNewRequest(formValues);
    }
  }

  addNewRequest(currency: Currency): boolean {
    this.currencyService.addNewCurrency(currency).subscribe(
      data => {
        this.alertService.success('Success!');
        this.currencyService.currenciesAdded.emit(data);
        this.dialogRef.close();
        return true;
      },
      error => {
        this.handleError(error);
      }
    );
    return false;
  }

  updateRequest(currency: Currency): boolean {
    currency.id = this.currency.id;
    this.currencyService.updateCurrency(currency).subscribe(
      data => {
        this.alertService.success('Success!');
        this.currencyService.currenciesUpdated.emit(currency);
        this.dialogRef.close();
        return true;
      },
      error => {
        this.handleError(error);
      }
    );
    return false;
  }

  cancel() {
    this.dialogRef.close();
  }

  handleError(error) {
    if (error === 409) {
      this.isDoublicatedCurrency = true;
    } else this.alertService.error('Error!');
    this.isSaving = false;
    return false;
  }
}
