import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { DocumentType } from 'src/app/shared/models/document-type.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ProductRegistrationsActivityCategoriesEnum } from '../../../admin-portal-configs/enums/product-registrations-activity-categories.enum';
import { DocumentTypeService } from '../../../admin-portal-configs/services/document-type.service';
import { RegistrationActivityService } from '../../../admin-portal-configs/services/registration-activity.service';
import { ProductRegistrationHelperService } from '../../../create-product-registration/service/product-registration-helper.service';
import { ProductRegistrationDetails } from '../../../product-registration/models/product-registration-details.model';
import { UploadDocumentComponent } from '../upload-document/upload-document/upload-document.component';
import { CancelationDetails } from '../../models/cancelation-details';
import { DocumentParentType } from '../../models/enums/document-parent-type.model';
import { DocumentTypeEnum } from '../../models/enums/document-type.enum';
import { UploadDocumentPopUpPurpose } from '../../models/enums/upload-document-pop-up-purpose.enum';
import { PauseDetails } from '../../models/pause-details';
import { AlertService } from '../../services/alert.service';
import { ProductRegistrationService } from '../../../product-registration/services/product-registration.service';
import { ModuleName } from '../../models/enums/module-name.enum';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {
  @Input() productRegistrationDetails: ProductRegistrationDetails;
  nextActivities: any[] = [];
  selectedActivityValue: number;
  @ViewChild('dropdown') dropdown: NgbDropdown;
  isShown: boolean;
  isSaving: boolean = false;
  inputActivityValue: string;
  productDocumentTypes: DocumentType[] = [];
  selectedActivityValueName: string;
  backURL: string;
  profilePictureUrl: string;
  createdBy: string;
  deleteBtnText: string;

  @Input() moduleName: ModuleName;
  @Output() deleteClicked = new EventEmitter();
  @Output() pauseResumeClicked = new EventEmitter();

  constructor(
    private authService: AuthService,
    private productRegistrationHelperService: ProductRegistrationHelperService,
    private registrationActivityService: RegistrationActivityService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private productRegistrationService: ProductRegistrationService,
    private documenTypeService: DocumentTypeService
  ) {}

  ngOnInit(): void {
    this.getNextActivities();
    this.processModuleData();
  }

  processModuleData() {
    if (this.moduleName === ModuleName.Registration) {
      this.backURL = `./company/${this.productRegistrationDetails?.productRegistration?.companyId}/registrations`;
      this.profilePictureUrl = this.productRegistrationHelperService.getSubmittedByProfileUrl(
        this.productRegistrationDetails
      );

      this.createdBy = this.productRegistrationHelperService.getSubmittedBy(
        this.productRegistrationDetails?.productRegistration
      );

      this.deleteBtnText = 'Cancel Request';
    }
  }

  ngAfterContentChecked() {
    this.isShown = this.dropdown?.isOpen();
  }

  getNextActivities() {
    this.registrationActivityService
      .getByName(
        this.productRegistrationDetails?.productRegistration
          ?.lastActivityLogViewModel?.activityName
      )
      .subscribe(res => {
        this.nextActivities = res.nextActivities.filter(
          activity =>
            activity.name !==
              ProductRegistrationsActivityCategoriesEnum.Cancelled &&
            activity.name !== ProductRegistrationsActivityCategoriesEnum.OnHold
        );
        if (
          this.nextActivities.find(
            x =>
              x.name ==
              ProductRegistrationsActivityCategoriesEnum.RegistrationLicenseObtained
          )
        ) {
          this.getRegistrationLicenseDocumentType();
        }
      });
  }

  get isReadonlyField(): boolean {
    return (
      !this.nextActivities?.length ||
      this.isProductPaused ||
      this.isCancelledStatus ||
      !this.canManageRegistrationStatus
    );
  }

  get currentColorStatus(): string {
    return this.productRegistrationHelperService?.getStatusColor(
      this.productRegistrationDetails?.productRegistration
    );
  }

  get canManageRegistrationStatus(): boolean {
    return this.authService.canManageRegistrationStatus;
  }

  get canPauseResumeProductRegistration(): boolean {
    return this.authService.canPauseResumeProductRegistration;
  }

  get canCancelProductRegistration(): boolean {
    return this.authService.canCancelProductRegistration;
  }

  get isCancelledStatus(): boolean {
    return this.productRegistrationHelperService?.isCancelledStatus(
      this.productRegistrationDetails?.productRegistration
    );
  }

  get isProductPaused(): boolean {
    return this.productRegistrationHelperService.isPausedStatus(
      this.productRegistrationDetails?.productRegistration
    );
  }

  get statusName(): string {
    return this.productRegistrationHelperService.getStatusName(
      this.productRegistrationDetails?.productRegistration
    );
  }

  get activityName(): string {
    return this.productRegistrationHelperService.getLastActivityName(
      this.productRegistrationDetails?.productRegistration
    );
  }
  get isNewSystemStatus(): boolean {
    return this.productRegistrationHelperService?.isNewSystemStatus(
      this.productRegistrationDetails?.productRegistration
    );
  }

  get isLagrWord(): boolean {
    return this.activityName?.length > 23;
  }

  updateActivity() {
    this.isSaving = true;
    this.applyInputActivityValues();
    this.productRegistrationService
      .updateProductRegistrationActivity(
        this.productRegistrationDetails.productRegistration?.id,
        this.selectedActivityValue,
        this.productRegistrationDetails.productRegistration?.acceptanceNumber,
        this.productRegistrationDetails.productRegistration
          ?.registrationLicenseNumber
      )
      .subscribe(
        res => {
          this.isSaving = false;
          this.alertService.success('Request has been updated.');
          this.productRegistrationDetails.productRegistration = res;
          this.dropdown.close();
          this.resetValues();
          this.getNextActivities();
        },
        error => {
          this.isSaving = false;
          this.alertService.error('Something went wrong!');
        }
      );
  }

  applyInputActivityValues() {
    if (this.inputActivityValue) {
      this.isUploadLicense
        ? (this.productRegistrationDetails.productRegistration.registrationLicenseNumber = this.inputActivityValue)
        : (this.productRegistrationDetails.productRegistration.acceptanceNumber = this.inputActivityValue);
    }
  }

  resetValues() {
    this.inputActivityValue = null;
    this.selectedActivityValue = null;
  }

  uploadLicense(): void {
    this.isSaving = true;
    const dialogRef = this.dialog.open(UploadDocumentComponent, {
      hasBackdrop: true,
      width: '600px',
      maxHeight: '95vh',
      data: {
        popUpPurpose: UploadDocumentPopUpPurpose.AddNewDocument,
        parentTypeId: this.productRegistrationDetails.productRegistration.id,
        parentType: DocumentParentType.ProductRegistration,
        productDocumentTypes: this.productDocumentTypes,
        formTitle: 'Upload Required Document',
        isUploadLicense: true
      }
    });

    dialogRef.afterClosed().subscribe((output: any) => {
      if (output) {
        this.updateActivity();
      } else {
        this.isSaving = false;
        this.resetValues();
      }
    });
  }

  getRegistrationLicenseDocumentType(): void {
    this.documenTypeService
      .getDocumentTypeByName(DocumentTypeEnum.RegistrationLicense)
      .subscribe(resp => {
        this.productDocumentTypes = [];
        this.productDocumentTypes.push(resp);
      });
  }

  get isDisabledActionBtn(): boolean {
    return (
      !this.selectedActivityValue ||
      (this.hasInputField && !this.inputActivityValue)
    );
  }

  private get acceptanceNoObtainedActivityId(): number {
    return this.nextActivities?.find(
      x =>
        x.name ==
        ProductRegistrationsActivityCategoriesEnum.AcceptanceNumberObtained
    )?.id;
  }

  private get registrationLicenseObtainedActivityId(): number {
    return this.nextActivities?.find(
      x =>
        x.name ==
        ProductRegistrationsActivityCategoriesEnum.RegistrationLicenseObtained
    )?.id;
  }

  get hasInputField(): boolean {
    return (
      this.selectedActivityValue &&
      (this.selectedActivityValue == this.acceptanceNoObtainedActivityId ||
        this.selectedActivityValue ==
          this.registrationLicenseObtainedActivityId)
    );
  }

  get isUploadLicense(): boolean {
    return (
      this.selectedActivityValue == this.registrationLicenseObtainedActivityId
    );
  }

  get pauseDetails(): PauseDetails {
    return this.productRegistrationHelperService.getPauseDetails(
      this.productRegistrationDetails?.productRegistration
    );
  }

  get cancelationDetails(): CancelationDetails {
    return this.productRegistrationHelperService.getCancelationDetails(
      this.productRegistrationDetails?.productRegistration
    );
  }

  get pausedResumeBtnTxt(): string {
    return this.isProductPaused ? 'Resume Request' : 'Pause Request';
  }

  sendClickedValueName(selectedActivityValueName: string) {
    this.selectedActivityValueName = selectedActivityValueName;
  }

  onDelete() {
    this.deleteClicked.emit();
  }

  onPauseResume() {
    this.pauseResumeClicked.emit();
  }
}
