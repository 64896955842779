<div
  [classList]="'d-flex position-relative checkbox-button-card '"
  [ngClass]="{
    disable: isDisabled,
    error: hasError,
    selected: cardSelected && !isDisabled
  }"
  id="wrapper"
  name="btn_submit"
>
  <span (click)="submit()">
    <input class="d-none" type="checkbox" />
  </span>
  <div class="container pt-4 pb-3">
    <div
      (click)="submit()"
      class="align-items-center justify-content-between my-1 pr-3 w-100"
      [class]="isServiceSelectionPage != true ? 'd-flex' : ''"
    >
      <div
        class="d-flex flex-column"
        [class]="
          isServiceSelectionPage === true
            ? 'col-9 float-right service-selection-font'
            : ''
        "
      >
        <span class="title">{{ title }}</span>
        <p class="description">
          {{ description }}
        </p>
      </div>
      <div
        *ngIf="iconPath"
        [class]="isServiceSelectionPage === true ? 'col-3' : ''"
      >
        <img [src]="iconPath" class="image-contianer" />
      </div>
    </div>
    <hr class="col-9" *ngIf="hasInputControls" />
    <form
      class="input-controls-section"
      [class]="
        title != serviceTypes.Trucking
          ? 'input-controls-padding col-9'
          : 'col-11'
      "
      *ngIf="hasInputControls"
    >
      <div
        class="d-flex align-items-center"
        *ngFor="let item of inputOptions; let i = index"
        [ngClass]="{
          'w-50': title != serviceTypes.Trucking,
          'ml-4': hasAirFreightExtraPadding(item)
        }"
      >
        <input
          [class]="!canSelectMultipleOptions ? 'radio-input' : 'checkbox-input'"
          [type]="!canSelectMultipleOptions ? 'radio' : 'checkbox'"
          [name]="!canSelectMultipleOptions ? 'radio' : item"
          [id]="item"
          [value]="item"
          (change)="elementChecked($event)"
          [disabled]="disableOption(item) || isDisabled"
        />
        <div class="input-option">
          {{ item }}
        </div>
        <div class="input-label">
          {{ getInputLabelAtIndex(i) }}
        </div>
      </div>
    </form>
  </div>

  <div class="circlCon" (click)="submit()" *ngIf="cardSelected && !isDisabled">
    <svg-icon class="check" name="white-check"></svg-icon>
  </div>
</div>
<div class="error-badge" *ngIf="hasError && errorMessage">
  <svg-icon name="mark!"></svg-icon>
  {{ errorMessage }}
</div>
