import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ShipmentStatusEnum } from 'src/app/shared/models/enums/shipment-status';
import { TransportTypeEnum } from 'src/app/shared/models/enums/transport-type.enum';
import { SearchCriteria } from 'src/app/shared/models/shipment/search-criteria.model';
import { HelperService } from 'src/app/shared/services/helper.service';
import { LocalStoreManager } from 'src/app/shared/services/local-store-manager.service';
import { ShipmentActorsViewFilterData } from '../../models/shipment-actors-view-filter-data.model';
import { ShipmentActorsViewFilter } from '../../models/shipment-actors-view-filter.model';
import { ShipmentsService } from '../../shipments.service';
import { ShipmentsListViews } from '../../models/shipments-list-views.model';
import { ShipmentHelpersService } from '../../services/shipment-helpers.service';
import { ActivatedRoute } from '@angular/router';
import { ShipmentListViewEnum } from '../../enums/shipment-list-view.enum';

@Component({
  selector: 'app-shipments-list-filters',
  templateUrl: './shipments-list-filters.component.html',
  styleUrls: ['./shipments-list-filters.component.scss']
})
export class ShipmentsListFiltersComponent implements OnInit {
  states: string[] = [];
  progresses: string[] = [];
  statuses: string[] = [];
  modesOfTransport: string[] = [];
  types: string[] = [];
  markers: string[] = [];

  filtersForm: UntypedFormGroup = new UntypedFormGroup({});

  RESUMED: string = 'Resumed';
  ONHOLD: string = 'On Hold';

  DRAFTSHIPMENT: string = 'Draft Shipment';

  searchCriteria: SearchCriteria;

  shipmentActorsViewFilterData: ShipmentActorsViewFilterData;
  isClearCustomFilters: boolean = false;

  constructor(
    private shipmentsService: ShipmentsService,
    private helperService: HelperService,
    private localStorage: LocalStoreManager,
    private shipmentHelpersService: ShipmentHelpersService,
    private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ShipmentsListFiltersComponent>
  ) {}

  ngOnInit(): void {
    if (this.data['searchCriteria']) {
      this.searchCriteria = this.data['searchCriteria'];
    }

    if (this.data['shipmentActorsViewFilterData']) {
      this.shipmentActorsViewFilterData = this.data[
        'shipmentActorsViewFilterData'
      ];
    }

    this.helperService.getShipmentFilters().subscribe(filters => {
      this.states = filters?.states;
      this.progresses = filters?.progresses;
      this.statuses = filters?.statuses;
      this.modesOfTransport = filters?.transportModes;
      this.types = filters?.types;
      this.markers = filters?.markers;
      this.processFiltersData();

      this.intializeForm();
      this.setPreSelectedFilters();
    });
  }

  processFiltersData() {
    this.states = this.states.filter(
      s => s != ShipmentStatusEnum.Draft && s != ShipmentStatusEnum.UserDraft
    );

    this.statuses = this.statuses.filter(
      s =>
        s != this.RESUMED &&
        s != this.ONHOLD &&
        s != ShipmentStatusEnum.Archived &&
        s != this.DRAFTSHIPMENT
    );

    this.modesOfTransport = this.modesOfTransport.filter(
      t => t != TransportTypeEnum.Trucking
    );
  }

  setPreSelectedFilters() {
    let selectedStates = this.searchCriteria?.status;
    let selectedStatuses = this.searchCriteria?.lastActivities;
    let selectedTransportModes = this.searchCriteria?.transportMode;
    let selectedTypes = this.searchCriteria?.transactionType;
    let selectedProgresses = this.searchCriteria?.progress;
    let selectedMarkers = this.searchCriteria?.markers;

    selectedStates?.forEach(state => {
      this.setFormControlValue(state, true);
    });

    selectedStatuses?.forEach(status => {
      this.setFormControlValue(status, true);
    });

    selectedTransportModes?.forEach(mode => {
      this.setFormControlValue(mode, true);
    });

    selectedTypes?.forEach(type => {
      this.setFormControlValue(type, true);
    });

    selectedProgresses?.forEach(progress => {
      this.setFormControlValue(progress, true);
    });

    selectedMarkers?.forEach(marker => {
      this.setFormControlValue(marker, true);
    });
  }

  intializeForm() {
    this.states.forEach(filterContent => {
      this.filtersForm.addControl(filterContent, new UntypedFormControl(false));
    });

    this.progresses.forEach(filterContent => {
      this.filtersForm.addControl(filterContent, new UntypedFormControl(false));
    });

    this.statuses.forEach(filterContent => {
      this.filtersForm.addControl(filterContent, new UntypedFormControl(false));
    });

    this.modesOfTransport.forEach(filterContent => {
      this.filtersForm.addControl(filterContent, new UntypedFormControl(false));
    });

    this.types.forEach(filterContent => {
      this.filtersForm.addControl(filterContent, new UntypedFormControl(false));
    });

    this.markers.forEach(filterContent => {
      this.filtersForm.addControl(filterContent, new UntypedFormControl(false));
    });
  }

  save() {
    let selectedStates = this.getSelectedFilters(this.states);
    let selectedStatuses = this.getSelectedFilters(this.statuses);
    let selectedTransportModes = this.getSelectedFilters(this.modesOfTransport);
    let selectedTypes = this.getSelectedFilters(this.types);
    let selectedProgresses = this.getSelectedFilters(this.progresses);
    let selectedMarkers = this.getSelectedFilters(this.markers);

    this.searchCriteria.status = selectedStates;
    this.searchCriteria.lastActivities = selectedStatuses;
    this.searchCriteria.transportMode = selectedTransportModes;
    this.searchCriteria.transactionType = selectedTypes;
    this.searchCriteria.progress = selectedProgresses;
    this.searchCriteria.markers = selectedMarkers;

    this.shipmentsService.onSearchCriteriaChange.emit(this.searchCriteria);
    this.localStorage.saveSyncedSessionData(
      this.shipmentActorsViewFilterData,
      'shipmentActorsViewFilterData'
    );

    this.cancel();
  }

  getSelectedFilters(filterContent: string[]): string[] {
    let selectedFilters = [];
    filterContent.forEach(data => {
      if (this.getFormControlValue(data)) {
        selectedFilters.push(data);
      }
    });
    return selectedFilters;
  }

  getFormControlValue(controlName: string): boolean {
    return this.filtersForm.controls[controlName].value;
  }

  setFormControlValue(controlName: string, value: boolean): void {
    this.filtersForm.controls[controlName].patchValue(value);
  }

  cancel() {
    this.dialogRef.close();
  }

  clearFilters() {
    Object.entries(this.filtersForm.controls).forEach(([key, value]) => {
      if (value) {
        this.setFormControlValue(key, false);
      }
    });

    this.isClearCustomFilters = true;
  }

  getNumberofSelectedFilters(filterContent: string[]): number {
    let selectedFilterssNumber = 0;

    Object.entries(this.filtersForm.controls).forEach(([key, value]) => {
      if (filterContent?.includes(key)) selectedFilterssNumber += value.value;
    });
    return selectedFilterssNumber;
  }

  get totalNumberOfSelectedFilters(): number {
    let totalNumberOfSelectedFilters = this.isClearCustomFilters
      ? 0
      : this.shipmentActorsViewFilterData?.totalNumberOfSelectedFilters;

    return (
      this.getNumberofSelectedFilters(this.states) +
      this.getNumberofSelectedFilters(this.progresses) +
      this.getNumberofSelectedFilters(this.statuses) +
      this.getNumberofSelectedFilters(this.modesOfTransport) +
      this.getNumberofSelectedFilters(this.types) +
      this.getNumberofSelectedFilters(this.markers) +
      (totalNumberOfSelectedFilters ?? 0)
    );
  }

  customFiltersChanged(shipmentActorsViewFilter: ShipmentActorsViewFilter) {
    this.shipmentActorsViewFilterData = {
      totalNumberOfSelectedFilters:
        shipmentActorsViewFilter?.shipmentActorsViewFilterData
          ?.totalNumberOfSelectedFilters,
      customerIds:
        shipmentActorsViewFilter.shipmentActorsViewFilterData?.customerIds,
      accountManagerIds:
        shipmentActorsViewFilter?.shipmentActorsViewFilterData
          ?.accountManagerIds,
      submitterIds:
        shipmentActorsViewFilter?.shipmentActorsViewFilterData?.submitterIds
    };

    if (!this.searchCriteria) {
      this.searchCriteria = {};
    }
    this.searchCriteria.customerCompaniesIds =
      shipmentActorsViewFilter.customerCompaniesIds;
    this.searchCriteria.submittedBy = shipmentActorsViewFilter.submittedById;
  }

  get isOperationsOversightView(): boolean {
    let isOperationsOversightView =
      this.currentShipmentsListView?.view ===
      ShipmentListViewEnum.OperationsOversight;
    return isOperationsOversightView;
  }

  get currentShipmentsListView(): ShipmentsListViews {
    return this.shipmentHelpersService.getCurrentShipmentsListView(
      this.currentView
    );
  }

  get currentView(): string {
    return this.route.snapshot.queryParams.view;
  }
}
